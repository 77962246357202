
.banner-section {
  
  background-image: linear-gradient(180deg, #f21d59, #f21d59);
  border-top: 1px solid #7a0022;
  /* margin-bottom: 30px; */
  padding: 10px 20px 10px 30px;
  display: block;
}
.banner-section-nomargin {
  background: white;
  border-bottom: 1px solid #f21d59;
  margin-bottom: 30px;
  padding: 10px 20px 10px 30px;
  display: block;
}

.download-container {
  margin-left: auto;
  margin-right: auto;
  display: -ms-flexbox;
  display: flex;
  /* width: 50%; */
}

.download-container p {
  color: white;
  font-weight: bold;
  top: 3px;
  position: relative;
  font-size: 20px;
  line-height: 25px
}
.banner-text {
  color: white;
  font-weight: bold;
  top: 10px;
  position: relative;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 0px;
}

a.download-app {
  font-size: 12px;
  margin-top: 0.5%;
  text-decoration: underline;
  color: black;
}

a.download-app:hover {
  color: #f21d59;
  text-decoration: underline;
}

.cta-download {
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  -ms-flex-pack: center;
  justify-content: center;
  top: 10px;
  position: relative;
}

/*
.left-column {
  width: 66.67%;
}

.left-column p {
  margin-left: 5%;
  font-weight: 800;
  font-size: 24px;
  margin-top: 2%;
}

.right-column {
  width: 33.33%;
}

.img-container {
  background-color: grey;
  width: 40%;
  height: 100px;
  border-radius: 50%;
  border: 1px solid white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
}

.sign-up-banner,
.img-container {
  opacity: 0;
  animation-name: bounceIn;
  animation-delay: 750ms;
  animation-duration: 600ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}
.img-container {
  animation-delay: 0.5s;
}
.sign-up-banner:nth-child(2) {
  animation-delay: 2.75s;
}
.sign-up-banner:nth-child(3) {
  animation-delay: 3s;
}
.sign-up-banner:nth-child(4) {
  animation-delay: 3.15s;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

button.sign-up {
  width: fit-content;
  height: auto;
  margin-top: 9%;
  display: block;
  z-index: 10;
  position: absolute;
  margin-left: 48%;
  border-radius: 5px;
  border: 2px solid grey;
  transition: color 0.25s ease;
  color: grey;
  background-color: white;
}

button.sign-up:hover {
  color: white;
}

button.sign-up::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: grey;
  transform-origin: left;
  transition: width 0.25s ease;
  z-index: -1;
}

button.sign-up:hover::after {
  width: 100%;
}
*/
