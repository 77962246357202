.toolbar {
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 9;
  border-bottom: 1px solid #464646;
  top: 0;
  padding-top: 5px
}


.auth__navigation {
  /* width: calc(100vw - 200px); */
  width:100%;
  padding: 0px 20px 0 20px;
}

.toolbar__toggle-button {
  float: left;
  top: 10px;
  position: relative;
}

.authtoolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

#full-nav .authtoolbar__logo {
  width: 110px;
  top: 5px;
  position: relative;
}


#full-navSignup .authtoolbar__logo {
  width: 110px;
  top: 5px;
  position: relative;
}

#mobile-nav .authtoolbar__logo {
  width:110px;
  align-self: center;
  top: 5px;
  position: relative;
}

.authtoolbar__logo a img {
  width: 120px;
  margin: 0px auto;
  display: block;
}

.authtoolbar__logo a:hover {
  color: white !important;
  text-decoration: none !important;
  font-size: 20px;
}

.toolbar_navigation-items {
  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toolbar_navigation-item {
  float: right;
  padding-left: 20px;
  color: #2f2f2f !important;
  cursor: pointer;
  font-size: 14px;
}

.toolbar_navigation_authlinks {
  float: right;
  padding-top: 5px;
}

.toolbar_navigation-item:hover {
  text-decoration: none;
}

a.toolbar_navigation-item:hover {
  color: #f21d59 !important;
  text-decoration: none;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.eventDropDown {
  float: inherit;
  overflow: hidden;
  height: 50px;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.75);
  z-index: 1;
  top: 5px;
}
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content a:hover {
  text-decoration: none;
  color: #f21d59;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.dropdown-content li {
  list-style: none;
}

.eventDropDown:hover .dropdown-content {
  display: block;
  margin-top: 3%;
}

/* Media Queries */

@media (max-width: 800px) {
  #full-nav {
    display: none;
  }

  .toolbarSignup {
    padding: 10px 10px 20px 10px !important;
    height: 95px ;
    border-bottom: 1px solid gainsboro
}

 

  #full-navSignup{
    display: none;
  }

  .container{width: 100% !important}

  #mobile-nav {
    display: block;
    height: 50px;
  }

  #authleft {
    display: none;
  }

  #authright {
    position: relative;
    padding-left: 0px !important;
  }

  #mobile-authlinks {
    display: block;
    height: 35px;
    border-top: 1px solid grey;
  }

  .maincontent {
    position: relative;
    background: #fafafa;
    /* background-color: linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%); */
    /* top: 80px; */
  }
}

@media (min-width: 801px) {
  #full-nav {
    display: block;
    height: 55px;
  }

  #full-navSignup{
    display: block;
    height: 55px;
  }

  #mobile-nav {
    display: none;
  }


.toolbarSignup{
  border-bottom: 1px solid #464646; 
}

  #mobile-authlinks {
    display: none;
  }
}

@media (min-width: 576px) {
  .container {
    /* max-width: 100% !important; */
  }
}



#full-nav .nav-signup {
  display: inline-block;
  background-color: #f21d59;
  border: none;
  color: #fff !important;
  padding: 7px 11px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #bd0036;
  cursor: pointer;
}

#full-navSignup .nav-signup{
  display: inline-block;
  background-color: #f21d59;
  border: none;
  color: #fff !important;
  padding: 7px 11px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #bd0036;
  cursor: pointer;
}

#mobile-nav .nav-signup{
  display: inline-block;
  background-color: #f21d59;
  border: none;
  color: #fff !important;
  padding: 7px 11px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #bd0036;
  cursor: pointer;
}

.toolbarSignup #mobile-nav .nav-signup {
  display: block;
  background-color: #f21d59;
  border: none;
  color: #fff !important;
  padding: 7px 11px;
  border-radius: 3px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #bd0036;
  cursor: pointer;
  text-align: center;
  max-width: 95%;
  margin: 0px auto;
}

#full-navSignup .nav-login,
#full-navSignup .nav-logout {
  display: inline-block;
  background-color: #fbfcfd;
  border: none;
  color: #777;
  padding: 7px 11px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  margin-right: 10px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #adadad;
  cursor: pointer;
}

.toolbarSignup #mobile-nav .toolbar__logo a img {
  width: 275px;
  position: relative;
  margin: 0px auto;
  margin-bottom: 10px;
}


#full-nav .nav-login,
#full-nav .nav-logout {
  display: inline-block;
  background-color: #fbfcfd;
  border: none;
  color: #777;
  padding: 7px 11px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  margin-right: 10px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #adadad;
  cursor: pointer;
}

#mobile-authlinks .nav-signup {
  display: inline-block;
  border: none;
  color: #f21d59 !important;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
}

#mobile-authlinks .nav-login,
#mobile-authlinks .nav-logout {
  display: inline-block;
  color: #777;
  padding: 7px 11px;
  margin-right: 10px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
}

header .row {
  margin-top: 0px !important;
}

.wrapper {
  max-width: 1200px;
}

.toolbar_navigation_authlinks_mobile {
  float: right;
}
