.promotedHero {
  background-color: #ffffff;
  border-bottom: 1px solid #f21d59;
}
h2.heroTitle {
  color: white;
  justify-content: space-around;
  height: 100%;
}

.d-none {
  display: block !important;
}

.promotedHero p {
  font-weight: 300;
}

.carousel {
  position: relative;
  margin: 0px auto;
  border-top: 1px solid grey;
  margin-bottom: 0px;
}
