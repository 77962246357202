.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: -10px;
  width: 70%;
  max-width: 400px;
  z-index: 999999999;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  padding: 10px;
}

.side-drawer.open {
  transform: translateX(0);
}

.sidebar-logo img {
  max-width: 150px !important;
}

.side-drawer ul {
  list-style: none;
}

.sidebar-logo {
  border-bottom: 0px solid #ee005a;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
