.map {
  width: 60%;
  height: 400px;
  border: 1px solid grey;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
}

.detail-buttons {
  width: 33%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}
button.send-itinerary,
button.print-details {
  margin-left: 0 !important;
  width: 40%;
}

#event .container {
  float: none !important;
}

#event #sender {
  margin-bottom: 30px;
}

#event .sender-name {
  top: 12px;
  position: relative;
  font-size: 18px;
}

#event .sender-message {
  clear: both;
  display: block;
}

#event-invite {
  margin-bottom: 20px;
}

#event h5.card-title {
  color: #212529;
}


.card-title {
  margin-bottom: 5px !important;
  font-size: 20px;
}

