
span{
  font-family: Roboto, sans-serif !important;}

  .htmlBox span{
   font-size: 14px !important
  }

/* General Buttons */

a .card-title {
  color: #dc3545;
}

.noBack {
  background: none !important;
  border: 0px !important;
}

.solidredbutton {
  border: 1px solid #f21d59 !important;
  padding: 10px;
  position: relative;
  color: #f7f7f7 !important;
  min-width: 100px;
  background: #ee0059;
  border-radius: 5px;
  margin: 0px auto;
  display: block;
  text-align: center;
  max-width: 500px;
}

.solidgreybutton {
  border: 1px solid #565656 !important;
  padding: 10px;
  position: relative;
  color: #f7f7f7;
  min-width: 100px;
  background: #676767;
  border-radius: 5px;
  margin: 0px auto;
  display: block;
  text-align: center;
  max-width: 500px;
}

.solidgreybuttonfull {
  border: 1px solid #565656 !important;
  padding: 10px;
  position: relative;
  color: #f7f7f7;
  min-width: 100px;
  background: #676767;
  border-radius: 5px;
  margin: 0px auto;
  display: block;
}

.claimButton {
  border: 1px solid #f21d59 !important;
  padding: 10px;
  position: relative;
  color: #f7f7f7;
  min-width: 100px;
  background: #ee0059;
  border-radius: 5px;
  margin: 0px auto;
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 30px;
  max-width: 300px;
}

.arrowFix {
  color: rgb(242, 29, 89);
  font-size: 30px;
  top: -7px;
  position: relative;
}

.checkFix {
  color: rgb(242, 29, 89);
  font-size: 30px;
  top: 6px;
  position: relative;
}

/* General Topagraphy */

li {
  text-align: left;
}

#bannerIntro li {
  color: white;
}

.profile-section a {
  color: #f21d59 !important;
}

.ReactModal__Content a {
  color: #f21d59 !important;
}


.secondary-title-bar span {
  font-size: 17px;
  font-weight: 500;
}

.secondary-title-bar-agent span {
  font-size: 15px;
  font-weight: 500;
  color: white;
}

.secondary-title-bar-agent {
  position: relative;
  display: block;
  height: 40px;
  background: #ee0059;
  border-bottom: 1px solid #b90b3b !important;
  padding: 10px;
  text-align: center;
}

span.page-header {
  font-weight: 400;
  font-size: 17px;
  top: 1px;
  display: block;
  position: relative;
}

h1 {
  margin-bottom: 1rem !important;
}

h4.subline {
  color: #212529;
  font-size: 1.3rem;
}

.redlabel {
  font-weight: 400 !important;
  font-size: 18px;
  color: #f21e59 !important;
  margin-bottom: 5px;
  clear: BOTH;
  display: block;
}

h2 {
  font-size: 2rem;
  font-weight: 200 !important;
}

@media (max-width: 800px) {
  .maincontent-push {
    position: relative;
    height: 100%;

}

#vendor .backbar {
  padding: 10px !important;
}

  /* Cards */
  /* .card-img-top {
    height: 120px;
  } */

  .twocolRight {
    display: none;
  }

  #packages .col-9,
  #articles .col-9,
  #article .col-9 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

/* #events{
  background:linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%);
} */

#login {
  text-align: center;
  /* background-image: url('https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Flandingback.jpg?alt=media&token=be9293af-4cfc-4760-a543-43aff61c6e08'); */
  background:linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%);
  height: 100%;
  background-size: cover;
}

.clear {
  clear: both;
  display: block;
}

html,
/* body, */
#root,
.fullheight,
.App {
  height: 100%;
}

html{
  background-color: #fafafa;
}

h1 {
  margin: 0px;
  padding-top: 10px;
  color: #f21d59 !important;
}

.section {
  margin: 0px auto;
  /* max-width: 1200px; */
  padding: 10px;
  display: block;
}

.whiteBack {
  background: white !important;
}

.floatLeft {
  float: left;
}

#invitation {
  padding-bottom: 20px;
}

.userPhoto {
  max-width: 60px !important;
}

/* SignUp */

#login .signup-box-container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.signup-box a {
  color: #f21d59 !important;
}

#login .signup-box {
  border-radius: 15px;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  max-width: 550px;
  color: #444;
  box-sizing: border-box;
  -webkit-box-direction: normal;
  margin: 0px auto;
  padding: 15px;
  cursor: pointer;
}

#login .signup-box button,
#login input {
  overflow: visible;
  width: 100%;
  margin-bottom: 10px !important;
  max-width: 300px;
  border-radius: 20px;
  padding: 6px 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #999;
  cursor: pointer;
}

#login .signup-box .social-button {
  overflow: visible;
  width: 100%;
  margin-bottom: 10px !important;
  max-width: 300px;
  /* float: left; */
  border-radius: 20px;
  padding: 6px 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #999;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  background-color: hsla(0, 0%, 100%, 0.8);
  margin: 0px auto;
  clear: both;
  display: block;
  cursor: pointer;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
  font-size: 14px;
  color: '#999';
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
  font-size: 14px;
  color: '#999';
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
  font-size: 14px;
  color: '#999';
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
  font-size: 14px;
  color: '#999';
}

.signup-box h3 {
  font-size: 25px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

.signup-button {
  display: inline-block;
  padding: 5px 15px;
  border: none;
  border-top-color: rgb(242, 29, 89);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(242, 29, 89);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(242, 29, 89);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(242, 29, 89);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 200ms ease;
  -o-transition: background 200ms ease;
  transition: background 200ms ease;
  color: #f21d59;
  float: right;
  border: 1px solid #f21d59;
  margin-top: 15px;
  background-color: #ffffff73;
  cursor: pointer;
}

.signup-box-info {
  margin: auto;
  margin-top: 15px;
  font-size: 16px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.signup-box .title-area {
  margin-bottom: 35px;
  margin-top: 35px;
}

.signup-box button {
  background-color: #f21d59;
  border: 1px solid #f21d59;
  color: white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
  cursor: pointer;
}

.signup-box button:hover {
  background-color: #cc164a;
  border: 1px solid #e01952;
  color: white !important;
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
}

.signup-box .left {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.textleft {
  text-align: left;
}

.signup-questions {
  margin-bottom: 20px;
}

.signup-question {
  margin-bottom: 20px;
}

.signup-tag-lable {
  font-size: 16px;
  display: block;
  clear: both;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 0px;
  color: #2d2d2d;
}

.signup-box .disclaimer {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
  font-size: 14px;
}

#inviteFull .UserAvatar--inner {
  margin: 0px 20px 0px 0px;
  border: 1px solid black;
}

#inviteFull .UserAvatar--inner {
  margin: 0px auto;
  border: 1px solid black;
  float: left
}

.UserAvatar--inner {
  margin: 0px auto;
  border: 0px solid black !important;
}

.UserAvatar--inner img{
  border: 1px solid black
}

.hfour {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  /* float: left; */
  font-size: 16px;
  margin-left: 5px;
  color: #424242;
}

.center {
  margin: 0px auto !important;
}

.signup-box .submitarea {
  margin: 0px auto;
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 200px;
}

button:disabled {
  background-color: grey !important;
}

.profile-box-title-area {
  margin-bottom: 35px;
  margin-top: 35px;
}

.message-box {
  width: 80%;
  margin: 0px auto;
  padding: 10px;
  top: 30px;
  position: relative;
  margin-bottom: 40px;
  border: 1px solid #d6d6d6;
  text-align: center;
}

.message-box h2 {
  font-weight: 600;
}

#page-numbers {
  list-style: none;

  display: block;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0px auto;
  margin-top: 20px;
}

#page-numbers li {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

button.download-app,
button.see-more,
button.checkout {
  background-color: #f21d59;
  color: white;
  border-radius: 7px;
  font-size: 15px;
  padding: 1%;
  border: 1px solid white;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  cursor: pointer;
  margin-top: 20px;
}

button.download-app:hover,
button.see-more:hover,
button.checkout:hover {
  background-color: #b81342;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}
/*
button.purchase,
button.services-button {
  padding-left: 0.5% !important;
  padding-right: 0.5% !important;
}*/

button.purchase,
button.services-button,
button.print-details,
button.send-itinerary,
button.contact-submit {
  display: inline-block;
  /* margin-left: 2%; */
  padding: 1%;
  background: none;
  color: #f21d59;
  position: relative;
  transition: color 0.25s ease;
  border: 2px solid #f21d59;
  border-radius: 5px;
}

button.purchase:hover,
button.services-button:hover,
button.print-details:hover,
button.send-itinerary:hover,
button.contact-submit:hover {
  color: white;
  background-color: #f21d59;
}

button.purchase::after,
button.services-button::after,
button.print-details::after,
button.send-itinerary::after,
button.contact-submit::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #f21d59;
  transform-origin: left;
  transition: width 0.25s ease;
  z-index: -1;
}

button.purchase:hover::after,
button.services-button:hover::after,
button.print-details:hover::after,
button.send-itinerary:hover::after,
button.contact-submit:hover::after {
  width: 100%;
}

#page-numbers > li {
  margin-right: 0.3em;
  color: blue;
  user-select: none;
  cursor: pointer;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  float: left;
}

.thumbnail {
  width: 100%;
  max-width: 100px;
  border: 1px solid grey;
  margin-right: 15px;
}

.thumbnailCol {
  max-width: 120px !important;
  width: 16.666667% !important;
}

.thumbnailColSmall {
  max-width: 50px !important;
  width: 16.666667% !important;
}

@media (min-width: 1000px) {
  /* .thumbnailCol {
    max-width: 115px !important;
  } */
}

.drawer-location {
  padding-bottom: 20px;
}

p {
  margin-left: 0px;
  color: #484848;
  max-width: 750px;
  font-weight: 300;
  font-family: Roboto, sans-serif !important;
}

span {
  font-size: 16px;
  font-weight: 300;
}

/* HOME */

.bottomNavLogo {
  width: 27px;
  position: relative;
  display: block;
  top: -1px;
}

.logo-btn {
  width: 35px;
  padding-bottom: 7px;
}

a.homeButton {
  padding: 19px 15px 14px 15px;
  background-color: #ee0059;
  border-radius: 10px;
  height: 105px;
  width: 125px;
  display: block;
  margin: 10px;
  color: #fff !important;
  /* border: 2px solid #c10249; */
}

.homeButton-text {
  clear: both;
  position: relative;
  display: block;
  text-align: center;
  margin-top: 0px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

#home li p {
  color: #393838;
  font-size: 18px;
  line-height: 20px;
  clear: both;
}

.intro {
  /* background: #db4c3f; */
  overflow: hidden;
  position: relative;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fintrobannerback.jpg?alt=media&token=7661de16-5827-4fff-8557-854920f235f0');
  background-position: center center;
}

.communityBack {
  /* background: #db4c3f; */
  overflow: hidden;
  position: relative;
  background-image: linear-gradient(45deg, rgb(242, 29, 89), rgba(245, 246, 252, 0)), url("https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fcommunitybannerback.jpg?alt=media&token=6a0509c9-d2db-4370-9043-2b25b343c9f5");
  max-height: 250px;
  background-position: center;
  background-size: cover;
}

.communityBack h2{color: white}

.stlbanner{
  /* background: #db4c3f; */
  overflow: hidden;
  position: relative;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/go-together-6b9cc.appspot.com/o/web%2Fintrobannerback.jpg?alt=media&token=7661de16-5827-4fff-8557-854920f235f0');
  background-position: center center;
}

#home .wrapper.top {
  padding-top: 70px;
  padding-bottom: 70px;
}

#home .wrapperShort.top {
  padding-top: 70px;
  padding-bottom: 70px;
}

#home .wrapperShort.top {
  padding-top:40px;
  padding-bottom: 30px;
}

.wrapper.top {
  padding: 200px 10px 50px 10px;
}

.wrapper.wide {
  width: 1104px;
}

.wrapper {
  position: relative;
  width: 85%;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 100%;
}

#home .intro-image {
  bottom: -40px;
  right: -40px;
}

.intro-image {
  position: absolute;
  right: -40px;
}




#home .hero-wrapper {
  width: 46%;
  margin-bottom: 40px;
  padding: 15px;
}

#home .hero-wrapperFull {
  width: 100%;
  margin-bottom: 40px;
  padding: 15px;
}

.intro h1,
.intro h2,
.intro .subline {
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.intro:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 5px;
  background: -webkit-linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.02)
  );
  background: -moz-linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.02)
  );
  background: -o-linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.02)
  );
  background: -ms-linear-gradient(
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.02)
  );
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

.intro h1,
.intro h2,
.intro .subline {
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

p.subline {
  color: #404040;
  font-size: 18px;
  line-height: 28px;
  /* margin-bottom: 50px; */
}

.whiteButton {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fff;
  color: #f21e58;
}

.whiteButton:hover {
  color: grey;
  transition: color 0.25s ease;
}

.hero-wrapper .button {
  margin: 10px 10px 10px 0;
}

.button {
  display: inline-block;
  padding: 12px 15px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background 200ms ease;
  -moz-transition: background 200ms ease;
  -o-transition: background 200ms ease;
  -ms-transition: background 200ms ease;
  transition: background 200ms ease;
  cursor: pointer;
}

.button.outlined-white {
  background: none;
  position: relative;
  -webkit-box-shadow: inset 0 0 0 2px #fff;
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff;
}

#home h2 {
  font-size: 48px;
  line-height: 48px;
  font-weight: 200;
  letter-spacing: -1px;
  margin-bottom: 15px;
}

.calltoaction h2 {
  font-size: 48px;
  line-height: 58px;
  font-weight: 200;
  letter-spacing: -1px;
  margin-top: 50px !important;
  margin-bottom: 15px;
}

#home.intro p {
  font-weight: 300 !important;
}

body {
  font-family: Roboto, sans-serif !important;
}

.locationCard {
  /* border-Right: 1px solid #e6e6e6 !important; */
  padding: 0px 20px 0px 0px;
}

@media screen and (max-width: 1024px) {
  .wrapper,
  .wrapper.top {
    width: 100%;
    /* padding-left: 40px;
    padding-right: 40px; */
  }
  #home .intro-image {
    right: -160px;
  }
}

@media screen and (max-width: 768px) {

  .locationCard {
    border-Right: 0px solid #e6e6e6 !important;
    padding: 0px 20px 0px 0px;
  }

  #home .wrapper.top {
    padding-top: 50px;
    padding-bottom: 220px;
  }
  #home .intro-image {
    right: auto;
    left: 50%;
    margin-left: -304px;
    bottom: auto;
    top: 60%;
  }
  .wrapper .hero-wrapper {
    text-align: center;
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {

  #login .signup-box {
    border-radius: 0px !important;
  }

  #home .hero-wrapper {
    text-align: center;
  }
  #home .wrapper.top {
    padding-top: 40px;
    padding-bottom: 220px;
  }
  p,
  p.subline {
    font-size: 14px;
    /* line-height: 130%; */
    font-weight: 200px;
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
    margin-top: 50px;
  }
}

.features .feature.padding-top {
  padding-top: 50px;
}
.features .feature.padding-bottom {
  padding-bottom: 50px;
}

.features {
  clear: both;
}

.feature .wrapper {
  max-width: 900px;
}

.features .feature img {
  vertical-align: middle;
}

.no-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.helpTopic {
  padding: 10px;
}

#helpTopic .card {
  margin-bottom: 10px;
}

.noTopPadding {
  padding-top: 0px !important;
}

.padding-top {
  padding-top: 50px !important;
}

.features .feature {
  background-image: -webkit-linear-gradient(top, #fafafa, #f3f3f3);
  background-image: -moz-linear-gradient(top, #fafafa, #f3f3f3);
  background-image: -o-linear-gradient(top, #fafafa, #f3f3f3);
  background-image: -ms-linear-gradient(top, #fafafa, #f3f3f3);
  background-image: linear-gradient(to bottom, #fafafa, #f3f3f3);
  overflow: hidden;
}

#home .feature-item-icon {
  padding-right: 8px;
  margin-bottom: -7px;
  height: 50px;
  padding-bottom: 10px;
}

.inline-block {
  display: inline-block;
}

.align-middle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

#home h3,
.features h3 {
  font-size: 35px;
  line-height: 44px;
  font-weight: 200 !important;
  margin-top: 30px;
  margin-bottom: 5px;
}


p.subline {
  font-weight: 200;
}

#services .breakleft {
  margin-left: -250px;
  margin-bottom: 40px;
}

#services .breakright {
  margin-right: -250px;
  margin-bottom: 40px;
}

.red-back {
  background-image: linear-gradient(
    to bottom,
    #ee005a,
    #cf0031
  ) !important;
}

.redlink {
  color: #f21d59 !important;
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
}

.white {
  color: white !important;
}
h1.white {
  color: white !important;
}

.reallywhite {
  color: white !important;
}

/* End Home */

/* #auth-home .carousel .slider-wrapper.axis-horizontal .slider .slide {
  padding: 5px;
 
} */

#promotedArticles .carousel .slider-wrapper.axis-horizontal .slider .slide {
  padding: 5px;
  /* background: #f21d59; */
}

#promotedArticles{
  /* background-color: #f21d59; */
  /*   */
}

#homeSectionGrey .carousel .slider-wrapper.axis-horizontal .slider .slide {
  padding: 5px;
  background: #dbdbdb;
}

#homeSectionGrey{
  background-color: #dbdbdb;
}




.carousel{
  border-top: 0px;
}

#auth-home .card {
  overflow: hidden;
  height: 100%;
  min-height: 100%;
}

#auth-home .card .card-body{
  border: 0px !important;
  padding: 0px;
}

/* #auth-home .slide div {
border: 1px solid black;
border-radius: 20px;
height: 100%;
min-height: 100%;
} */

#auth-home h2 {
  margin-top: 20px;
  text-align: center;
}

.messagebox {
  max-width: 900px;
}

.card-subtitle {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.card-section {
  border-top: 1px solid #e0e0e0;
  padding: 10px 0px 10px 0px;
}

.right {
  float: right;
}

.bold {
  font-weight: 600;
}

.card:hover {
  box-shadow: 0 !important;
  transition: 0.3s;
}

.location-list-item {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #e0e0e0;
  clear: both;
  padding-left: 0px !important;
}

.location-list-item a {
  color: black;
}

.text-center {
  text-align: center;
  justify-content: center;
}

/* Filter */
.filterSection h5 {
  font-size: 14px;
  position: RELATIVE;
  top: 5px;
}

.filterSection {
  border-bottom: 1px solid #e8e8e8;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-size: 14px;
}

/* Wizard */

.wizard-border-top {
  border-top: 1px solid #b90a3b;
  margin-top: 50px;
}

.wizard-section-border {
  border-bottom: 1px solid #b90a3b;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wizard-section-border-white {
  border-bottom: 1px solid white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.wizard-section-border-ten {
  border-bottom: 1px solid #b90a3b;
  padding-top: 10px;
  padding-bottom: 10px;
}

.wizard-section {
  padding-top: 20px;
  padding-bottom: 20px;
}

.wizard-section-ten {
  padding-top: 10px;
  padding-bottom: 10px;
}

#wizard {
  background: #fafafa;
  padding-bottom: 50px;
}

.helpTipTitle {
  color: #b9143b;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 5px;
  font-weight: 500;
}

/* Profile */

.profile-section {
  border-top: 1px solid #f21d59;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
}

#profile-edit .UserAvatar--inner {
  margin: 0px !important;
}

#profile-edit .submitarea {
  margin-top: 20px;
}

#profile-edit input {
  margin-bottom: 15px;
}

#profile-edit .UserAvatar {
  margin-right: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
}

/* services */

#services .wrapper {
  position: relative;
  width: 1200px !important;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 100%;
}

/* AUTHENTICATED */
@media (min-width: 377px) {
  .nav-logout a {
    color: hsl(343, 89%, 53%) !important;
  }

  .nav-logout {
    padding-top: 10px;
    background: none;
    width: 100%;
    color: white !important;
  }
}

@media (max-width: 376px) {



  #login .signup-box-container{
    height: 100%;
    display: block;
  }

  #login .signup-box{
    height: 100%;
  }


  .nav-logout a {
    color: white !important;
  }

  .nav-logout {
    padding-top: 10px;
    position: absolute;
    bottom: 0px;
    background: #f21d59;
    width: 100%;
    color: white !important;
    left: 0px;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
}

#authleft {
  float: left;
  width: 200px;
  background-color: #f5f5f5;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 9999999;
  border-right: 1px solid #c7c7c7;
  display:none
}

#authright {
  position: relative;
  /* padding-left: 200px; */

  height: 100%;
  width: 100%;
  /* display: grid; */
}

/* General */

.inactive {
  color: #adadad !important;
}

.no-borders {
  border: 0px !important;
}

.padding-top-five {
  padding-top: 5px;
  display: block;
}

.padding-top-twenty {
  padding-top: 20px;
}

.padding-top-ten {
  padding-top: 10px;
  display: block;
}

.padding-top-fifteen {
  padding-top: 15px;
}

.padding-ten {
  padding: 10px !important;
}
.padding-forty {
  padding: 40px !important;
}

.alignTop {
  vertical-align: top;
}

.center-message {
  margin-top: 40px;
  display: block;
}

.key {
  padding: 16px 10px 0px 10px;
}

.keyUser {
  padding: 2px 3px 2px 3px;
  background: #d8d8d8;
  border: 1px solid grey;
}

.keyMatch {
  padding: 2px 3px 2px 3px;
  background: #b90b3b;
}

.keyOtherUser {
  padding: 2px 3px 2px 3px;
  background: #757575;
  border: 1px solid #6d0320;
  border: 1px solid #424141;
}

.tag-user {
  font-size: 14px;
  padding: 2px 3px 2px 3px;
  background: #ffffff;
  border-radius: 5px;
  position: relative;
  top: -2px;
  color: #363636;
  margin-right: 5px;
  text-transform: capitalize;
  border: 1px solid #5d5d5d;
  display: inline-block;
  margin-bottom: 5px;
}


.eventTypeTagLeft {
  font-size: 12px;
  padding: 2px 3px 2px 3px;
  background: #fefefe;
  color: #313131;
  border-radius: 5px;
  display: block;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  
  border: 1px solid #9e9e9e;
}


#location .tag-user:hover{
  background: #f21d59;
  color:white
}

.tag-otherUser {
  font-size: 14px;
    padding: 2px 3px 2px 3px;
    background: #ffffff;
    border-radius: 5px;
    position: relative;
    top: -2px;
    color: #ee0158;
    margin-right: 5px;
    text-transform: capitalize;
    border: 1px solid #ee0158;
    display: inline-block;
}

.tag-match {
  font-size: 14px;
  padding: 2px 3px 2px 3px;
  background: #ee00598c;
  border-radius: 5px;
  position: relative;
  top: -2px;
  color: white;
  margin-right: 5px;
  text-transform: capitalize;
  border: 1px solid #ee0059;
  display: inline-block;
}

.tag-big {
  font-size: 15px;
  padding: 5px 10px 5px 10px;
  background: #2f2f2f;
  border-radius: 5px;
  position: relative;
  top: -2px;
  color: #ffffff;
  margin-right: 5px;
  text-transform: capitalize;
  border: 1px solid grey;
}

.secondary-title-bar {
  position: relative;
  display: block;
  height: 45px;
  /* background: #fafafa; */
  background: white;
  border-bottom: 1px solid #ee0059 !important;
  padding: 10px;
  text-align: center;
  /* font-style: italic; */
}

.secondary-title-bar-fixed {
  position: fixed;
  display: block;
  height: 40px;
  /* background: #fafafa; */
  background: white;
  border-bottom: 1px solid #ee0059   !important;
  padding: 10px;
  text-align: center;
  z-index: 99999;
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100% !important;
  display: block;
}

.display-block {
  display: block;
  margin-bottom: 15px;
}

h5 {
  font-weight: 400 !important;
  color: #3c3c3c !important;
  font-size: 19px !important;
}

.contentbox {
  max-width: 1000px;
  margin: 0px auto;
  /* border: 1px solid #e0e0e0; */
  background-color: #fbfbfb;
  padding-bottom: 15px
}
.hero-preview {
  height: 100px;
}

.noticebox {
  background-color: whitesmoke;
  padding: 10px;
  border: 1px solid #d6d6d6;
  margin-bottom: 20px;
}

.noticeboxBottom {
  background-color: whitesmoke;
  padding: 10px;
  border: 1px solid #d6d6d6;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 30px
}

.greybox {
  /* background-color: #e4e4e4; */
  background: linear-gradient(180deg,rgba(167,25,65,1) 0%, rgba(242,29,89,1) 59%);
  padding: 10px 10px 20px 10px;
  border: 1px solid #b21844;
  margin-bottom: 0px;

}

.lightGreyBack{
  background: #f7f7f7;
}

/* Tables */

.table-bold {
  font-weight: 800;
  font-size: 16px;
}

.red {
  color: #f21d59 !important;
}

.green {
  color: #209203;
}

.error-message {
  color: red;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 10px;
  line-height: 18px !important;
  display: block;
}

[type='submit'] {
  color: white;
  background-color: #f21d59;
  margin-bottom: 20px;
}

#

.UserAvatar {
  /* margin-bottom: 15px; */
  margin-right: 15px;
}

#profile-block .UserAvatar {
  margin-right: 0px !important;
}

/* Admin  */

.redbutton {
  border: 1px solid #f21d59 !important;
  padding: 10px;
  top: 10px;
  position: relative;
  color: #f21d59 !important;
  min-width: 100px;
  background: transparent;
  cursor: pointer;
  display: block;
  max-width: 400px;
  margin: 0px auto;
}

.solidRedbutton {
  border: 1px solid #f21d59 !important;
  padding: 10px;
  position: relative;
  color: white !important;
  min-width: 100px;
  background: #f21d59;
  cursor: pointer;
  display: block;
  max-width: 400px;
  margin: 0px auto;
  text-align: center;
  border-radius: 5px;
}

.whitebutton {
  border: 1px solid #f21d59 !important;
  padding: 10px;
  position: relative;
  color: #f21d59 !important;
  min-width: 100px;
  background: white;
  cursor: pointer;
  display: block;
  max-width: 400px;
  margin: 0px auto;
  text-align: center;
  font-weight: 500;
}


.redButtonBlock {
  border: 1px solid #f21d59 !important;
  padding: 10px;
  top: 10px;
  position: relative;
  color: #f21d59 !important;
  min-width: 100px;
  background: white;
  cursor: pointer;
  display: block;
  max-width: 400px;
  margin: 0px auto;
}

.css-26l3qy-menu {
  background-color: white;
  z-index: 999999999;
}

.DraftEditor-editorContainer{
  z-index: inherit;
}

.stackedRedButtonBlock {
  border: 1px solid #f21d59 !important;
  background: #ee0059;
  border-radius: 5px;
  padding: 10px;
  top: 10px;
  position: relative;
  color: white;
  min-width: 100px;
  cursor: pointer;
  display: block;
  margin: 0px auto;
  margin-top: 10px;
  max-width: 400px;
}
#vendor input[name="title"], input[type="url"]{
  width: 100%
}

.centered .center  {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-12%, -50%);
}
.stackedWhiteButtonBlock {
  border: 1px solid #f21d59 !important;
  background: white;
  border-radius: 5px;
  padding: 10px;
  top: 10px;
  position: relative;
  color: rgb(238, 0, 89);
  min-width: 100px;
  cursor: pointer;
  display: block;
  margin: 0px auto;
  margin-top: 10px;
  max-width: 400px;
}

a.stackedRedButtonBlock {
  color: white !important;
}

.redbutton.white {
  background: white !important;
}

.greybutton {
  border: 1px solid #4b4b4b !important;
  padding: 10px;
  top: 10px;
  position: relative;
  color: #4b4b4b;
  min-width: 100px;
  background: transparent;
  cursor: pointer;
}

#admin a {
  color: #f21d59;
}

.redbutton:hover {
  border: 1px solid rgb(187, 19, 67);
  padding: 10px;
  top: 10px;
  position: relative;
  color: rgb(187, 19, 67);
  background: transparent;
}

#admin button {
  background: none;
  border: none;
  color: #f21d59;
  clear: both;
  display: block;
  cursor: pointer;
}

.adminlistitem .label {
  font-weight: 300;
  color: #ffffff;
  font-size: 13px;
}

#admin {
  padding-bottom: 65px;
  background: #fafafa;
}

.adminlistheader {
  background-color: #444444 !important;
}

.adminlistitem {
  border-bottom: 1px solid #e8e8e8;
  padding: 5px 0px 5px 0px;
  list-style: none;
  display: grid;
}

.adminlistitem:nth-child(odd) {
  background-color: #fff;
}

.adminlistitem:nth-child(even) {
  background: #f7f7f7;
}

.adminlistitem:last-child {
  border: 0px;
}

#admin input {
  overflow: visible;
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
  padding: 6px 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #a7a7a7;
  margin-right: 10px;
  clear: both;
  color: #424242;
  margin-bottom: 10px;
}

#admin textarea {
  overflow: visible;
  width: 100%;
  margin-bottom: 10px !important;
  border-radius: 5px;
  padding: 6px 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #a7a7a7;
  min-height: 200px;
}

span.section-header {
  font-size: 20px;
  color: #333333;
  font-weight: 400;
}

.border-box {
  border: 1px solid #d6d6d6;
  padding: 10px;
  margin-bottom: 20px;
}

.greyback {
  background-color: #fbfbfb;
}

/* .container {
  float: left;
} */

/* Drawer / Menu */

#right-filter .material-icons {
  color: rgb(242, 29, 89);
  font-size: 20px;
  top: 2px;
  position: relative;
  float: left;
  margin-right: 10px;
  width: 25px;
}

.activeMemuItem {
  color: #bc233c !important;
  font-weight: 500 !important;
}

.drawerMenuList li {
  list-style: none;
  padding-bottom: 15px;
  line-height: 21px;
}

/* .nav-logout {
  padding-top: 10px;
  position: absolute;
  bottom: 0px;
  background: #f21d59;
  width: 100%;
  color: white !important;
  left: 0px;
  align-items: center;
  text-align: center;
} */

#profile-block {
  border-bottom: 1px solid #e2e2e2;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

/* Headers */

#headers {
  position: fixed;
  z-index: 99999;
  top: 0;
  /* width: calc(100% - 200px); */
  width: 100%;
}

#headersTop {
  position: fixed;
  z-index: 91;
  top: 0px;
  /* width: calc(100% - 200px); */
  width: 100%;
}

/* .titlebar {
  background-color: white;
  padding: 10px 15px 10px 15px;
  text-align: center;
  justify-content: center;
} */

.topavatar {
  top: 5px;
  position: relative;
}

#spacer {
  padding: 10px;
}

.fullwidth {
  width: 100%;
}

/* Forms */

.inputLabel {
  font-size: 15px;
  display: block;
  clear: both;
  font-weight: 200;
  margin-bottom: 5px;
  margin-top: 10px;
}

/* Rich Editor */

.rdw-editor-main {
  padding: 10px;
}

.rdw-editor-wrapper {
  border: 1px solid #a7a7a7;
  margin-bottom: 10px;
}

.backbar {
  background-color: #ee0059;
  padding: 8px 15px 10px 15px;
  height: 40px;
  color: white;
}

.backbar a {
  color: white !important;
}

#admin .backbar a {
  color: white !important;
}

#admin .backbar  {
  background-color: #444;
}

/* Widgets */

.widget {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(234, 234, 234, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: 1px solid #e2e2e2;
  border-radius: 7px;
  margin-bottom: 20px;
}

span.widgetHeader {
  font-size: 18px;
  text-transform: uppercase;
  color: #444444;
}

/* Article */

#article h1 {
  margin: 0px;
  padding-top: 10px;
  color: #131313 !important;
  font-weight: 200 !important;
}

p.author {
  margin-top: 5px;
  font-weight: 200;
  color: #717171 !important;
  font-size: 12px;
}

/* Modal */

.modalHeader-text {
  font-weight: 300;
  font-size: 16px;
  color: #ffffff !important;
  text-align: center;
  top: 3px;
  position: relative;
}

.modalHeader {
  background-color: #f3265d;
  padding: 10px !important;
  border-bottom: 1px solid #f3265d !important;
  text-align: center;
  /* margin-bottom: 20px; */
}

.ReactModal__Overlay--after-open {
  z-index: 9999999;
}

.oddeven:nth-child(odd) {
  background-color: white;
  border: 1px solid #d4d4d4;
  padding: 5px;
}
.oddeven:nth-child(even) {
  background-color: #f7f7f7;
  border: 1px solid #d4d4d4;
  padding: 5px;
}

.oddeven .userName {
  font-size: 14px;
  color: #afafaf;
}

.oddeven .name {
  font-size: 16px;
  color: #f21d59;
}

.addicon {
  margin-top: 15px;
}

#message-form textarea {
  width: 100%;
  margin-bottom: 20px;
}

.rc-time-picker-panel {
  z-index: 99999999999999 !important;
  width: 170px;
  position: absolute;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.DayPicker {
  margin: 0px auto;
  display: block;
}

.ffcategory {
  font-size: 12px;
  color: #f21d59;
}

.ffsearch {
  max-width: 350px;
}

/* Footer */

.footer-content {
  margin: 0px auto;
  max-width: 1200px;
  padding: 20px 10px 30px 10px;
  width: 100%;
}

.middleIcon{
  position: relative; 
  top: -4px
}

@media (min-width: 801px){
#startBar {
    position: fixed;
    z-index: 2;
    bottom: 0px;
    /* background-color: #f7f7f7; */
    /* border-top: 1px solid #dedede; */
    width: 100%;
    height: 70px;
    padding: 7px;
    /* width: calc(100% - 200px); */
}
#startBarCentered {
  position: fixed;
  z-index: 2;
  bottom: 0px;
  /* background-color: #f7f7f7;
  border-top: 1px solid #dedede; */
  width: 100%;
  height: 60px;
  padding: 7px;
  /* width: calc(100% - 200px); */
}
}

@media (max-width: 800px) {
  #startBar {
    position: fixed;
    z-index: 2;
    bottom: 0px;
    background-color: #f7f7f7;
    border-top: 1px solid #dedede;
    width: 100%;
    height: 60px;
    padding: 7px;
    width: 100%;
  }
  #startBarCentered {
    position: fixed;
    z-index: 2;
    bottom: 0px;
    background-color: #f7f7f7;
    border-top: 1px solid #dedede;
    width: 100%;
    height: 60px;
    padding: 7px;
    width: 100%;
  }

  #bottomNav {
    position: fixed;
    z-index: 2;
    bottom: 0px;
    background-color: rgb(242,29,89);
    border-top: 1px solid #860127;
    width: 100%;
    height: 80px;
    padding: 15px 0px 0px 0px;
    width: 100%;
  }

  #bottomNav .col-3 {
    justify-content: center !important;
    display: flex;
  }

  .activeBottomNav .material-icons {
    color: #921538 !important;
  }

  .middleIcon{
    position: relative; 
    top: -4px
  }

  .footer {
    position: fixed;
    z-index: 2;
    bottom: 0px;
    background-color: #ffffff;
    width: 100%;
    /* height: 60px; */
    padding: 10px;
    width: 100%;
    border-top: 1px solid #333333;
  }

  #breakoutFooter {
    position: fixed;
    z-index: 2;
    bottom: 0;
    width: 100%;
    background-color: #b90b3a;
    height: 40px;
    padding: 10px;
  }

  .adminActionBar {
    background: white;
    top: 90px;
    width: 100%;
    z-index: 2;
    position: fixed;
    border-bottom: 1px solid #b9143a;
  }

  .modalActionBar {
    background: white;
    width: 100%;
    z-index: 2;
    position: static;
    margin-bottom: 20px;
    border-bottom: 1px solid #b9143a;
  }

  .actionBarPush {
    /* margin-top: 35px; */
    padding-top: 40px;
  }
}

@media (min-width: 801px) {
  #events .actionBarPush {
    margin-top: 37px;
    /* padding-top: 20px; */
  }

  #bottomNav {
    position: fixed;
    z-index: 2;
    bottom: 0px;
    background-color: rgb(242,29,89);
    border-top: 1px solid #860127 !important;
    width: 100%;
    height: 70px;
    padding: 7px;
    /* left: 200px; */
    /* width: calc(100% - 200px); */
    width: 100%;
  }

  #bottomNav .col-3 {
    justify-content: center !important;
    display: flex;
  }

  .activeBottomNav .material-icons {
    color: #921538 !important;
  }

  .footer {
    position: fixed;
    z-index: 2;
    bottom: 0px;
    background-color: #ffffff;
    width: 100%;
    height: 60px;
    padding: 10px;
    /* width: calc(100% - 200px); */
    width: 100%;
    border-top: 1px solid #333333;
  }

  

  #breakoutFooter {
    position: fixed;
    z-index: 2;
    bottom: 0;
    /* width: calc(100% - 200px); */
    width: 100%;
    background-color: #b90b3a;
    height: 40px;
    padding: 10px;
  }

  .adminActionBar {
    background: white;
    top: 40px;
    /* width: calc(100% - 200px); */
    width: 100%;
    z-index: 9999999999;
    position: fixed;
    border-bottom: 1px solid #b9143a;
  }
}

.footer-link {
  color: white !important;
}

.footer-link-withborder {
  color: white !important;
  border: 1px solid white;
  padding: 5px 10px 5px 10px;
}

/*  Active Event */

.distance-bar {
  padding: 10px;
  border-top: 1px solid #b90b3a;
  border-bottom: 1px solid #b90b3a;
  background-color: white;
}

@media (max-width: 767px) {

  .mobileShow{
    display: block;
  }

  .spotlightTitle{
    font-size: 20px !important;
  }

  #promotedArticles{
    padding: 0px !important
  }

  .mobileHide{
    display: none !important;
  }


  .mobileHideInline{
    display: none !important;
  }


  #vendorScreenBody{
    
    width: 100% !important;
  }

  .action-bar {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: white;
    border-top: 2px solid #b90d3b;
    padding-top: 10px;
  }
}

@media (min-width: 768px) {
  .mobileShow{
    display: none;
  }

  #vendorScreenBody{
    float: left;
    width: calc(100% - 120px);
  }

  .carousel .control-next.control-arrow:before {
    border-left: 12px solid #f21d59 !important;
  }

  .carousel .control-prev.control-arrow:before{
    border-right: 12px solid #f21d59 !important;
  
  }
  
  .mobileHide{
    display: block;
  }

  .mobileHideInline{
    display: inline-block !important;
  }


  .action-bar {
    position: fixed;
    bottom: 0px;
    padding: 10px;
    /* width: calc(100% - 200px); */
    width: 100%;
  }
}

.action-icon .material-icons {
  background-color: #ee0059;
  border-radius: 50%;
  padding: 10px;
}

.action-item {
  width: 100px;
  text-align: center;
  margin: 0px auto;
}

.action-item a {
  color: rgb(33, 37, 41);
}

@media (max-width: 767px) {
  .action-item-text {
    clear: both;
    position: relative;
    display: block;
    text-align: center;
    margin-top: 5px;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .action-item-text {
    clear: both;
    position: relative;
    display: block;
    text-align: center;
    margin-top: 5px;
  }
}

.active-location-title-bar {
  position: relative;
  top: 40px;
  display: block;
  height: 50px;
  background: #fafafa;
  border-bottom: 1px solid #b90b3b;
  padding: 5px 10px 10px 10px;
  text-align: center;
}

.active-location-title {
  text-align: center;
  top: 3px;
  display: block;
  position: relative;
  font-weight: 400;
  font-size: 18px;
  color: #2f2f2f;
}

/* CheckBox */

.rc-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: #f21e59;
  background-color: #ffffff;
  -webkit-transition: border-color 0.3s
      cubic-bezier(0.68, -0.55, 0.27, 1.55),
    background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  -o-transition: border-color 0.3s
      cubic-bezier(0.68, -0.55, 0.27, 1.55),
    background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition: border-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55),
    background-color 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.rc-checkbox-checked:hover .rc-checkbox-inner {
  border-color: #b90b3a !important;
}

.rc-checkbox-checked .rc-checkbox-inner {
  border-color: #b90b3a !important;
  background-color: #f21e59;
}

.rc-checkbox:hover .rc-checkbox-inner,
.rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: #f21e59 !important;
}

/* Checkout */

.bold-title {
  font-weight: 500;
}

/* from event create */
.add-btn {
  margin-top: 10px;
}

.instructions {
  font-weight: 400;
  color: #f21d59;
  margin-bottom: 10px;
  display: block;
}

/* Conversation */

.post {
  padding-bottom: 10px;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 10px;
}

.postInput {
  height: 100%;
  bottom: 0px;
  width: 100%;
  left: 0;
  background-color: whitesmoke;
  padding: 10px;
  border: 0px;
}

.postArea {
  height: 75%;
  padding: 10px;
  overflow-y: scroll;
}

.postInputArea {
  height: 35%;
  border-top: 1px solid grey;
}

.postAvatar {
  max-width: 45px !important;
}

.postName {
  display: block;
  font-size: 12px;
  color: #ababab;
  font-weight: 400;
}

.postTime {
  display: block;
  font-size: 12px;
  color: #ababab;
  font-weight: 400;
  float: right;
}

.notificationIcon {
  top: 7px;
  display: block;
  position: relative;
}

.notificationCount {
  position: relative;
  left: 26px;
  top: -15px;
  background-color: #ee0059;
  border-radius: 100%;
  color: white;
  font-weight: 600 !important;
  font-size: 20px;
  border: 1px;
  width: 50%;
}

/* General Layout */

.sectionBoxHeader {
  text-align: center;
  margin-bottom: 35px;
  padding-top: 10px;
}

.sectionBox {
  background-color: white;
  border-top: 1px solid #c3c3c3;
}

.inline-block {
  display: inline-block !important;
  width: 100%;
}

/* 
Vendor */

#vendorClaim {
  padding-top: 30px;
}

.infoBox {
  padding: 10px;
  height: 90%;
  border: 1px solid #adadad;
  background-color: white;
  margin-bottom: 30px;
}

.betaContent {
  padding-top: 40px;
  display: block;
  position: relative;
  border-bottom: 1px solid #3c3c3c;
  padding-bottom: 40px;
  background-color: white;
}

.betaInput {
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.betaForm {
  padding: 10px;
  margin: 10px 0 0 0;
}

.fontFourty {
  font-size: 40px !important;
}

.callSection {
  margin-bottom: 40px;
}

.noBorderRight {
  border-right-width: 0px !important;
}

.actionButton {
  padding: 5px;
}

.actionButtonInactive {
  padding: 5px;
  color: #333333;
  /* border-bottom: 1px solid #b9143a; */
  cursor: pointer;
}

.actionButtonActive {
  padding: 5px;
  font-weight: 600;
  /* background-color: #fbfbfb; */
  color: #ee0059;
  border-bottom: 2.5px solid #ee0059;
}

.counterRed {
  margin-left: 5px;
  border-radius: 12px;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  color: #b90a3a;
  margin-left: 10;
  top: 3px;
  text-align: center;
  border: 1px solid #b90a3a;
}

.counterRed span,
.counterGrey span {
  display: block;
  position: relative;
  font-size: 13px;
}

.counterGrey {
  margin-left: 5px;
  background: white;
  border-radius: 12px;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  color: #4e4e4e;
  text-align: center;
  border: 1px solid #cccccc;
}

.counterRed span,
.counterGreyBig span {
  display: block;
  position: relative;
  font-size: 14px;
  top: 2px;
  font-weight: 500
}

.counterGreyBig {
  margin-left: 5px;
  background: white;
  border-radius: 20px;
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
  color: #4e4e4e;
  text-align: center;
  border: 1px solid #cccccc;
  top: -4px;
}

#imageUpload input {
  overflow: visible;
  width: 100%;
  max-width: 600px;
  /* border-radius: 5px; */
  padding: 0px !important;
  border-width: 0px !important;
  border-style: solid;
  /* border-color: #a7a7a7; */
  margin-right: 10px;
  /* color: #424242; */
  margin-bottom: 10px;
  clear: both !important;
  display: block !important;
  position: relative !important;
  margin-top: 10px !important;
}

.heroInputImage {
  max-width: 300px;
}

#imageUpload input {
  width: 105px !important;
}

.bottom-push {
  padding-bottom: 50px;
}

.grey-back {
  background: #fafafa;
}

input {
  overflow: visible;
  padding: 5px;
  border: 1px solid #d4d4d4;
  min-width: 100%;
}

#vendor input {
  min-width: auto;
}

#vendor #locationForm input {
  min-width: 100%;
}

textarea {
  padding: 5px;
  border: 1px solid #d4d4d4;
}

.fsquare {
  width: 150px;
}

#eventDetailsForm .DayPicker-Months {
  border: 1px solid #d2d2d2;
  margin-left: 0px !important;
}



#wizardCalendar .DayPicker-wrapper {
 max-width:250px
}

#wizardCalendar .DayPicker-Months {

  margin-left: 0px !important;
}

.rc-time-picker-input {
  border: 1px solid grey !important;
  border-width: 1px !important;
  border-color: grey !important;
}

.inviteCard {
  margin-bottom: 10px;
}

#eventsBlack .inviteCard {
  border: 1px solid #3a3a3a !important;
}

#notifications .inviteCard {
  border: 1px solid #ee0059 !important;
}

.invite-title {
  font-size: 18px;
  font-weight: 400 !important;
  color: #b9143a;
}

.invite-font {
  font-size: 14px;
}

.cardButton {
  margin-right: 30px;
  margin-bottom: 10px;
}

.ReactModal__Content--after-open {
  height: 100%;
}

#message-form textarea {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #dedede;
  min-height: 200px;
}

.distance {
  font-size: 12px;
  color: #3e3e3e;
}

.pushfifty {
  top: 50px;
  display: block;
  position: relative;
}

.pushforty {
  top: 40px;
  display: block;
  position: relative;
}

.pushtwenty {
  top: 20px;
  display: block;
  position: relative;
}

.bottompushtwenty {
  margin-bottom: 20px;
}

#newplans input {
  margin: 0px auto;
  display: block;
}

.twentyPush {
  margin-top: 20px;
}

.fortyPush {
  margin-top: 40px;
}

/* Edit Event */

.location-title {
  font-weight: 400;
  color: #212529;
  font-size: 15px;
  line-height: 14px;
  display: block;
  padding-bottom: 5px;
}

#event {
  padding-bottom: 45px;
}

#events .postArea {
  height: 75%;
  padding: 10px;
  overflow-y: scroll;
  background-color: white;
}

#event .planners {
  border-bottom: 1px solid #2d2d2d;
  background: white;
  position: fixed;
  z-index: 5;
  width: 100%;
}

.planners {
  border-bottom: 1px solid #2d2d2d;
  background: white;
}


#events .UserAvatar--inner {
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  max-width: 30px;
  width: 30px;
  max-height: 30px;
  height: 30px;
  float: left;
  margin-right: 0px;
}

.plannerName span {
  top: 3px;
  display: block;
  position: relative;
}

#events .postInputArea {
  border: 1px solid #e4e4e4;
}

#events .postArea {
  border: 1px solid #e4e4e4;
}

/* Editor */

#lastEdit {
  clear: both;
}

#lastEdit p {
  font-size: 13px;
}

.marginLeftTen {
  margin-left: 10px;
}

.approvalMessage {
  padding: 0px 0 0px 0;
}

.approvalMessage span {
  font-style: italic;
  color: #585858;
  font-weight: 500;
}

.approvalStatus {
  font-size: 14px;
  top: 5px;
  position: relative;
  display: block;
  color: #b90a3a;
}

/* Connect  */
.header-button {
  border: 1px solid #f21d59 !important;
  padding: 5px;
  position: relative;
  color: #f21d59 !important;
  margin-bottom: 15px;
  margin-top: 15px;
  cursor: pointer;
  background: white !important;
}

/* Calendar */
.DayPicker-Day {
  padding: 8px 10px 7px 10px !important;
}

#analytics .DayPicker-Day {
  padding: 4px 6px 3px 6px !important;
}

#analytics .DayPicker {
  margin: 0px auto;
  display: block;
  width: 280px;
  float: right;
}



/* Cropper */

#cropper {
  height: 100%;
  position: fixed;
  display: block;
  width: 100%;
  top: 0px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.9);
}

#crop-image {
  padding: 10px;
  top: 60px;
  position: relative;
}

.secondaryBar {
  background-color: #585858;
  padding: 8px 15px 10px 15px;
  height: 40px;
  color: white;
}

/* Event */
#eventDate {
  top: 5px;
  position: relative;
}
#eventGuestList {
  top: 5px;
  position: relative;
}

.addLocationButton {
  margin-top: 10px;
  padding: 10px 10px 5px 10px;
  background: #f21d59;
  /*border: 2px solid #f21d59;*/
  border-radius: 5px;
  color: white !important;
}

#eventDetailsForm {
  border-top: 1px solid #e8e8e8;
  margin-top: 20px;
}

#editingOverlay {
  background: rgba(238, 0, 89, 0.9);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  z-index: 999;
}

span.overlayMessage {
  color: white;
  font-size: 40px;
  line-height: 45px;
  text-transform: uppercase;
}

.overlayMessageArea {
  text-align: center;
  padding: 20px;
}

.overlayContentArea {
  margin-top: 100px;
  padding-bottom: 50px;
}

.overlayContentArea .UserAvatar {
  margin-right: 0px;
}

.overlayContentArea .UserAvatar--inner {
  margin: 0px auto;
  border: 1px solid white;
}

/* Vouchers */

.voucherListing {
  padding: 10px 0 10px 0;
}

/* loading dots */

.loading:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(238, 0, 89, 0);
    text-shadow: 0.25em 0 0 rgba(238, 0, 89, 0),
      0.5em 0 0 rgba(238, 0, 89, 0);
    text-shadow: 0.25em 0 0 rgba(238, 0, 89, 0);
  }
  40% {
    color: white;
    text-shadow: 0.25em 0 0 rgba(238, 0, 89, 0),
      0.5em 0 0 rgba(238, 0, 89, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgba(238, 0, 89, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
  }
}

span.loading {
  font-size: 70px;
  text-align: center;
  clear: both;
  display: block;
  position: relative;
  left: -20px;
}

/* Load Screen */

.middleMe img {
  max-width: 200px;
  margin-top: 35%;
}

.middleMe{
  padding-top: 50%
}

.middleMe span {
  color: white;
  clear: both;
  display: block;
  top: 20px;
  position: relative;
  font-size: 30px;
}

/* 
Forms */

#fullName .text-input {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid black;
  border-radius: 5px;
  /* box-shadow: 1px 4px 5px; */
  box-shadow: 3px 3px 3px 4px rgb(218 218 218 / 20%);
}

.copyLinkMessage {
  padding-top: 20px;
  display: block;
  padding-bottom: 10px;
}

.copyTitle {
  font-weight: 300;
  font-size: 24px;
}

#message-form {
  padding: 10px;
}

.tipBox {
  border-top: 1px solid #e0e0e0;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
}

.tipText {
  color: #464646;
  display: block;
  clear: both
}

.tipTitle {
  clear: both;
  display: block;
}

.centerText {
  text-align: center;
}

.guestListTitle {
  font-weight: 400 !important;
  font-size: 16px !important;
}

/* .startButton {
  background: #f21f5a;
  padding: 5px 10px 5px 10px;
  top: 10px;
  display: inline-block;
  margin-top: 10px;
  color: white !important;
  border-radius: 5px;
} */

.startButton {
  background: #f21f5a;
  padding: 5px 10px 5px 10px;
  /* top: 10px; */
  display: inline-table;
  margin-top: 10px;
  color: white !important;
  border-radius: 5px;
  position: absolute;
  /* z-index: 999999999; */
  top: px;
  right: 0px;
  border: 3px solid white;
  top: -10px;

}
.startButton span{

  font-weight: 500;
}

#events input{
  border-color: black;
}

.addGuest,
.scheduleButton {
  color: white !important;
  background-color: #f21d59;
  display: flex;
  justify-content: space-around;
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

[type='text'] {
  overflow: visible;
  width: 100%;
  margin-bottom: 10px !important;
  /* max-width: 600px; */
  /* border-radius: 5px; */
  padding: 6px 15px;
  border: 1px solid #d4d4d4;
  margin-right: 10px;
  clear: both;
  color: #424242;
  font-weight: 100;
  font-size: 14px;
}
/* ff search */
.search-input {
  margin-right: 0px !important;
}

/* Wizard */

select {
  border: 1px solid grey;
  background: white;
}

.tagFilterBtnContact {
  background-color: #ffffff;
  color: #636363;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #e8e8e8;
}

.tagFilterBtnMatchedContact {
  background-color: #ffffff;
  color: #636363;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #e8e8e8;
  font-weight: 600;
}

.tagFilterBtnContact {
  background-color: #ffffff;
  color: #636363;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #e8e8e8;
}



.tagFilterBtnNone {
  background-color: white;
  color: #f21d59;
  border: 1px solid #f21d59;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
}

.ReactModal__Content .tagFilterBtnNone {
  background-color: #ffffff;
  color: #f21d59;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #f21d59;
  min-width: 152px;
  margin-right: 30px;
}

.tagFilterBtnUser {
  background-color: #ffffff;
  color: #363636;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #5d5d5d;
}

.ReactModal__Content .tagFilterBtnUser {
  background-color: #ffffff;
  color: #f21d59;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #f21d59;
  min-width: 152px;
  margin-right: 30px;
}

.ReactModal__Content .tagFilterBtnActive{
  min-width: 152px;
  margin-right: 30px;
}



/* .tagFilterBtnUser {
  background-color: #d8d8d8;
  border: 1px SOLID grey;
  color: #272727;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
}*/

.tagFilterBtnMatchedContactSmall {
  background-color: #ffffff;
  color: #636363;
  padding: 5px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #e8e8e8;
  font-weight: 600;
  cursor: pointer;
}


.tagFilterBtnUserSmall {
  background-color: #ffffff;
  color: #363636;
  padding: 2px 5px 2px 5px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #5d5d5d;
  cursor: pointer;
}


.tagFilterBtnNoneSmall {
  background-color: white;
  color: #f21d59;
  border: 1px solid #f21d59;
  padding: 5px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
}


.tagFilterBtnOtherSmall {
  background-color: #ffffff;
  color: #ee0059;
  padding: 5px;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #ee0059;
}

.tagFilterBtnMatchedSmall  {
  background-color: #ee00598c;
  color: #ffffff;
  padding: 5px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #ee0059;
  font-weight: 600;
  cursor: pointer;
}

.tagFilterBtnMatchedBright {
  background-color: #ed0259;
  color: #ffffff;
  padding: 5px;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 15px;
  text-align: center;
  border: 2px solid #ffffff;
  font-weight: 600;
  cursor: pointer;
}

.tagFilterBtnActiveSmall {
  background-color: #f21d59;
  color: white;
  border: 1px solid #f21d59;
  padding: 5px;
  display: inline-block;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
  font-weight: 600;
}



.tagFilterBtnOther {
  background-color: #ffffff;
  color: #ee0059;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #ee0059;
  cursor: pointer;
}

/* .tagFilterBtnOther {
  background-color: #757575;
  color: white;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #424141;
} */

.tagFilterBtnMatched {
  background-color: #ee00598c;
  color: #ffffff;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #ee0059;
  font-weight: 600;
  cursor: pointer;
}

/* .tagFilterBtnMatched {
  background-color: #b90b3b;
  color: white;
  padding: 5px;
  display: block;
  width: 90%;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid #6d0320;
} */

.tagFilterBtnActive {
  background-color: #f21d59;
  color: white;
  border: 1px solid #f21d59;
  padding: 5px;
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 15px;
  text-align: center;
  width: 90%;
  font-weight: 600;
  cursor: pointer;
}

/* support */

.help-topic {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d4d4d4;
  color: #f21d59;
}

.help-topic:first-of-type {
  padding-top: 0px;
}

/* Places */

.favoriteIcon {
  top: 10px;
  display: block;
  position: relative;
  right: 1px;
}

.favoriteSection {
  border-top: 1px solid #f21d59;
  /* padding-top: 20px; */
  margin-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.flow-root {
  display: inline-block;
  width: 100%;
}

/* Promos */

#promoChart .rv-xy-plot {
  margin: 0px auto;
}

.promo {
  /* border: 1px solid #b90c3b; */
  margin-bottom: 10px;
  background: white;
  border-radius: 5px;
}

.promoBody {
  padding: 10px;
}

.promo-reward {
  clear: both;
  display: block;
  font-weight: 500;
  font-size: 18px;
}

.promo-criteria {
  clear: both;
  display: block;
  font-weight: 300;
  padding: 5px 0px 15px 0px;
}

.pin-field {
  width: 40px;
  margin-right: 10px;
}

.pin-code-btn {
  top: 15px;
  position: relative;
}

.pin-area {
  background-color: #f21d59;
}

.promo-container {
  padding-bottom: 50px;
}

.promo-bar {
  border-bottom: 1px solid #b9143a;
  background-color: white;
  /* margin-bottom: 10px; */
}

.award-title {
  color: #f31e59;
  /* text-align: center; */
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px;
  display: block;
}

.awardback {
  background: #ffffff;
  /* text-align: center; */
  border: 3px solid #f31e59;
  border-radius: 10px;
  padding: 15px 10px 20px 10px;
  /* margin-bottom: 10px; */
  margin: 0px auto;
  margin-top: 10px;
  max-width: 550px;
}

span.confirmMessage {
  color: white;
  font-size: 25px;
}
span.confirmBtn {
  display: block;
  position: relative;
  font-size: 75px;
  color: white;
  top: 40px;
}

.promoGrey {
  background: #f1f1f1;
}

.text-right {
  text-align: right;
}

li.adminlistitem {
  font-size: 13px;
}

li.adminlistitem span {
  font-size: 13px;
}

.footer span {
  cursor: pointer;
}

.activeBtn {
  padding-left: 5px;
  top: 1px;
  position: relative;
}

span.award-location {
  display: block;
}

/* Vendor Radio Buttons ?? Make General? */

#events input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 10px;
}

#events label {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-right: 10px;
}

#vendorSchedule .DayPicker-Month {
  margin-left: 0px !important;
  
}

#vendorSchedule{margin: 0px auto}

#vendorTimes{margin: 0px auto}

#vendorSchedule .DayPicker {
  margin: 0px !important;
}

/* Promotions */

/* .teaser {
} */

.sectiontitle {
  padding-top: 5px;
  padding-bottom: 5px;
}

#homeSections .sectiontitle {
  /* padding-bottom: 5px; */
}

.homeSection{ 
  padding: 0px 30px 0px 30px;
  margin-top: 30px;
  background: white;
}

.homeSectionAll{ 
  padding: 0px 10px 0px 10px;
  margin-top: 30px;
  background: white;
}

.homeSection.grey{
  background-color: #dbdbdb;
}

.vendorEventLocation {
  clear: both;
  display: block;
  font-size: 16px;
}

.vendorEventTeaser {
  font-size: 15px;
}
span.vendorEventTitle {
  font-size: 18px;
  font-weight: 400;
}

/* Stats */

.statbox {
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #b90a3b;
}

.stat {
  font-weight: 600;
  color: #b90a3b;
}

.statcat {
  font-weight: 400;
}

#articles a,
#home-bottom a {
  color: #f21d59 !important;
}

/* Location Details */

.phone {
  font-size: 15px;
}

.website {
  font-size: 15px;
  padding-top: 10px;
  display: block;
}

.chart-title {
  font-weight: 400;
  font-size: 12px;
}

.redeemed {
  font-weight: 400;
}

#topPick{
  display: block;
    position: fixed;
    z-index: 999999999;
    height: 100% !important;
    background: #ed0259;
    padding-top: 6  0px;
    max-width: 100%;
    width: 100%
}

.reportHeader {
  background-color: #676767;
  border-top: solid #5a5a5a 1px;
  padding: 5px 15px 5px 15px;
}

span.reportHeaderText {
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.loudStatus {
  font-size: 20px;
  font-weight: 400;
  padding: 10px;
  background: #e2e2e2;
}


#fullScreen{
  position: fixed;
    z-index: 1000000;
    height: 100%;
    width: 100%;
    top: 0px;
}

/* Stats Bar */

#statsbar {
  padding: 0px 10px 0px 10px;
  margin-top: 4px;
  background: #ee0059;
  border-top: 1px solid #c10249;
  color: white !important;
}

#statsbar a {
  color: white !important;
}

#statsbar span {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.numberCircle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: #fff;
  border: 1px solid #b90a3b;
  color: #ee0059;
  text-align: center;
  font: 32px Arial, sans-serif;
}

.statBarTitle {
  display: block;
  top: 10px;
  position: relative;
}

.statBarLeft {
  padding: 5px 10px 5px 5px;
  border-right: 1px solid white;
}

.statBarRight {
  padding: 5px 5px 5px 10px;
  /* padding: 5px; */
}

.pointsIcon {
  width: 20px;
  padding-bottom: 2px;
  margin-right: 4px;
}
/* 
Invite Icon */

#mobile-nav span {
  font-size: 16px;
  font-weight: 600;
}

/* Full Screen Invite */

#inviteFull {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  display: block;


  /* border: 4px solid #ad0030; */

}

#inviteFull p {

  margin: 0px;
}

.bottomActions {
  position: fixed;
  bottom: 0;
  width: 100%;
  border-top: 4px solid #ad0030;
  background: rgb(242, 29, 89);
  padding: 5px;
}

.bottomAcceptLogo {
  width: 40px;
  position: relative;
  display: block;
}

.icon-title {
  color: white;
  clear: both;
  display: block;
  font-size: 11px;
}

.inviteTitle {
  margin-bottom: 10px;
  font-size: 26px;
  padding: 0px 10px 0px 10px;
  line-height: 40px;
}

/* make standard link */
.suggestionLink {
  font-size: 15px;
  padding-top: 15px;
  display: block;
  padding-bottom: 10p;
  color: #f21d59;
  clear: both;
}

.packagethumb {
  width: 100%;
  height: auto;
  margin: 0px auto;
  position: relative;
  padding: 2px;
  max-width: 100px !important;
  float: left;
}

.mainTag {
  font-size: 13px;
  color: #757575;
  display: block;
  float: right;
}

/* .mainTagClear {
 clear: both;
 display: block;
 color: black;
 font-size: 14px;
} */

.mainTagClear {
  font-size: 12px;
  padding: 3px 5px 3px 5px;
  background: #f1f1f1;
  color: dimgrey;
  border-radius: 5px;
  clear: both;
  display: table;
}

.mainTagLeft {
  font-size: 12px;
  padding: 3px 5px 3px 5px;
  background: #f1f1f1;
  color: dimgrey;
  border-radius: 5px;
  display: block;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
}

.mainTagLeft.local{
  background: white;
  color: #f21d59;

  -webkit-box-shadow:inset 0px 0px 0px 1px #f21d59;
  -moz-box-shadow:inset 0px 0px 0px 1px #f21d59;
  box-shadow:inset 0px 0px 0px 1px #f21d59
}

#coldWeather .mainTagClear {
  font-size: 12px;
  padding: 3px 5px 3px 0px;
  background: none;
  color: rgb(58, 58, 58);
  border-radius: 5px;
  clear: both;
  display: table;
}

.carousel-indicators li {
  height: 15px !important;
  width: 15px !important;
  border-radius: 100% !important;
  background-color: rgb(151, 151, 151) !important;
}

.carousel-indicators .active {
  opacity: 1;
  background-color: #555555 !important;
}

#progressBarBack {
  border: 1px solid #d8d8d8;
  padding: 0px 5px 0px 0px;
  width: 100%;
  position: relative;
  display: block;
  background: white;
  margin: 10px 0px 10px 0px;
  border-radius: 20px;
}

#progressBarBackComplete {
  border: 1px solid #d8d8d8;
  width: 100%;
  position: relative;
  display: block;
  background: white;
  margin: 10px 0px 10px 0px;
}

.progressComplete {
  text-align: center;
  background-color: #5d5d5d !important;
  border: 1px solid grey !important;
}

.progressBar {
  border: 1px solid #880032;
  padding: 1px;
  position: relative;
  display: block;
  background-color: #ee0158;
  color: white;
  border-radius: 20px;
  background: linear-gradient(to right, #f5aabf 0%, #f21d59 100%);
  min-width: 5%;
}

.rewardCreateBox {
  /* border: 1px solid #d2d2d2; */
  /* padding: 10px; */
  /* background: #f7f7f7; */
}

.reward {
  padding: 10px 0px 10px 0px;
  border-top: 1px solid #cccccc;
}

.rewardTitle {
  color: #ee0158;
  font-size: 17px;
  font-weight: 500;
}

.criteriaBorder {
  padding: 10px;
  /* border: 1px solid #ee0158; */
  margin-bottom: 10px;
  margin-top: 10px;
  background: #f7f7f7;
  color: #0e0e0e;
  border-radius: 1rem;
}


.criteriaBorderGrey {
  padding: 10px;
  /* border: 1px solid grey; */
  margin-bottom: 10px;
  margin-top: 10px;
  background: #f7f7f7;
  color: #0e0e0e;
  border-radius: 1rem;
}

.progressGoal {
  position: relative;
  top: 3px;
  font-weight: 500;
  font-size: 18px;
}

.clickable{
  cursor: pointer
}


.progressTotal {
  position: relative;
  font-weight: 500;
  font-size: 18px;
}

/* In forms */
.criteria {
  padding: botom 10px;
}
/* // */

.criteriaText {
  font-weight: 400;
}

.criteriaHeader {
  font-weight: 00;
  color: #000000;
  font-weight: 600;
  font-style: italic;
}

.rewardIcon {
  top: 5px;
  position: relative;
  padding-right: 5px;
}

.criteriaHeaderComplete {
  font-weight: 00;
  color: #880132;
  font-weight: 600;
  font-style: italic;
  /* text-decoration: line-through; */
}

.validationType {
  font-weight: 800;
  color: #191919;
  clear: both;
  display: block;
}

.levelSection.col-12.col-sm-12.col-md-4.col-lg-4 {
  background: white;
  border-right: 1px solid #dadada;
  padding-top:10px;
}


.editCriteriaActive {
  position: relative;
  top: 50px;
  float: left;
  left: 20px;
  display: BLOCK;
  color: #00947b !important;
}

.editCriteria {
  position: relative;
  top: 50px;
  float: left;
  left: 20px;
  display: BLOCK;
  color: #ee0158 !important;
  
}

.redbuttonRight {
  border: 1px solid #8c0c30 !important;
  padding: 5px 10px 5px 10px;
  top: 2px;
  position: relative;
  color: #f21d59 !important;
  cursor: pointer;
  display: block;
  margin: 0px auto;
  float: right;
  background-color: #ee0158;
  color: white !important;
}

.redbuttonStrong {
  border: 2px solid #f21d59 !important;
  padding: 10px;
  top: 10px;
  position: relative;
  color: #f21d59 !important;
  min-width: 100px;
  background: transparent;
  cursor: pointer;
  display: block;
  max-width: 400px;
  margin: 0px auto;
  font-weight: 500;
}

.redbuttonRight span {
  font-weight: 400;
}

.promoImage {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  border-bottom: 1px solid #b90d3b;
}

/* .promoImage:after {
  content: '\A';
  position: absolute;
  width: 100%; height:100%;
  top:0; left:0;
  background:rgba(0,0,0,0.6);
  opacity: 10;
  transition: all 1s;
  -webkit-transition: all 1s; 
} */

.promoImage img {
  width: 100%;
  vertical-align: top;
  height: 20vh;
}

.details-search-area {
  padding: 20px 0px 0px 0px;
}

#event .card-body{
  padding: 15px !important;
}

.criteria {
  padding: 0px 0px 30px 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e1e1e1;
}

.analFilter {
  float: left;
  margin-right: 10px;
  z-index: 9999999;
  position: relative;
}

.analChart{
  margin-bottom: 30px;
}

.analCard{
  margin: 15px 0 10px 10px;
    width: 100%;
    border-right: 1px solid #cbcbcb;
    padding-left: 10px
}

.analLoud {
  color: #222;
  font-size: 24px;
  max-width: 160px;
  min-width: 65px;
  word-wrap: break-word;
  text-align: left;
}

.analQuite {
  color: #222;
  display: block;
  font-size: 13px;
  font-weight: normal;
  text-align: left;
}

.alertMessage{
  margin: 20px 0px 50px 0px;
  clear:both;
}

.analSide{
  border-right: 1px solid #d8d8d8;
  background-color: #dadada;
  padding:10px;
}

.analTime{
  font-size: 20px;
  font-weight: 500;
  color: #1f1f1f;
}

#pickerArea {
  border-top: 2px solid #ba0c3b;
  float: right;
}

.analMenuItem{
    font-size: 18px;
    padding-bottom: 5px;
    cursor: pointer;
}

span.clear.left.analSubHeader {
  padding: 10px 0px 10px 0px;
  color: #444444;
}

li.activeReportLink span {
color:white;
font-weight: 500;
}




.module {
 
  width: 100%;
  height: 150px;
  position: relative;
  overflow: hidden;
  /* margin: 20px; */
  background-size: contain !important;
  text-align: center;
}

.module > header {

  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px;
  background: inherit;
}
.module > header::before {
  content: "";
  position: absolute;
 
  left: 0;
  width: 200%;
  height: 100%;
  background: inherit;
  -webkit-filter: blur(4px);
  filter: blur(4px);
}
.module > header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25)
}
.module > header > span {
  margin: 0;
  color: white;
  position: relative;
  z-index: 1;
}

.module > header {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px;
  background: inherit;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.activeLevel div{
border-color:hsl(212, 100%, 39%) !important
}

/* Vendor */

#vendor .backbar {
  background-color: white;
  padding: 14px 15px 10px 15px;
  height: 60px;
  color: white;
  border-bottom: solid 1px #313131;
}

span.vendorAppTitle {
  color: #353535;
  font-size: 22px;
  line-height: 30px;
  margin-left: 5px;
  float: left
}

.appPickerDrawer {
  height: 100%;
  -webkit-box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5); 
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5); 
  position: fixed;
  top: 0;
  width: 70%;
  max-width: 300px;
  z-index: 200;
  /* -webkit-transform: translateX(-100%); */
  -ms-transform: translateX(-100%);
  /* transform: translateX(-100%); */
  /* -webkit-transition: -webkit-transform 0.3s ease-out; */
  /* transition: -webkit-transform 0.3s ease-out; */
  -o-transition: transform 0.3s ease-out;
  /* transition: transform 0.3s ease-out; */
  /* transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; */
  padding: 20px;
  background: white;
  border-right: 1px solid #4e4e4e;
}

.vendorHelpDrawer {
    height: 100%;
    background: white;
    position: absolute;
    width: 70%;
    max-width: 300px;
    /* -webkit-transform: translateX(-100%); */
    -ms-transform: translateX(-100%);
    /* transform: translateX(-100%); */
    /* -webkit-transition: -webkit-transform 0.3s ease-out; */
    /* transition: -webkit-transform 0.3s ease-out; */
    -o-transition: transform 0.3s ease-out;
    /* transition: transform 0.3s ease-out; */
    /* transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; */
    padding: 10px;
    margin: 0px;
    float: right;
    border-left: 1px solid #353535;
    right: 0px;
    top: 0px;
    z-index: 99999;
}

.vendorContent{
    /* margin: 0px auto; */
    /* max-width: 1200px; */
    /* padding: 15px; */
    display: block;
    min-width: 70%;
    position: relative;
    /* float: left;
    max-width: 70%; */
    height: 100%;
 

}



.appMenu a{
  padding: 10px 0px 0px 12px !important;
  display: block
}

#vendor {
  height: 100%;
  background: #fafafa;
}

#vendor .helpButton i{
  line-height: 29px;
  float: right;
}

.appTitle {
  clear: both;
  display: block;
  margin-bottom: 20px;
}

.appList{
  padding: 30px 0px 0px 5px;  
}

#vendor .activeMemuItem {
  color: black !important;
  font-weight: 500 !important;
}



#vendor .actionButtonInactive {
  padding: 5px;
  color: #ffffff;
  cursor: pointer;
}

#vendor .actionButtonActive {
  padding: 5px;
  color: #353535;
  border-bottom: none !important;
  background: #fafafa;
}

#vendor .actionButton {
    margin-bottom: 20px;
    padding: 10px 0px 10px 12px !important;
}


.vendorLeftNav {
  float: left;
  position: relative;
  display: block;
  height: 100%;
  background: #353535;
  padding-top: 10px;
  width: 10%;
  min-height: 100%;
}

div#experienceForm {
 
  min-width: 600px;
  
  padding-bottom: 100px;
}

div#experienceFormContent{
  /* max-width: 700px; */
}

div#previewer {
  float: left;
  background: #232323;
  width: 30%;
  height: 100%;
  padding: 10px;
  color: white;
  min-width: 300px;
  min-height: 100%;
}

div#previewer {
  float: right;
  background: #232323;
  width: 30%;
  height: 100%;
  padding: 10px 20px 20px 20px;
  min-width: 300px;
}

#previewTabs{
  color: white;
}

a.activePreviewTab {
  float: left;
  margin-left:10px;
}

a.activePreviewTab span {
  border: 1px solid white;
  padding: 2px;
  width: 75px;
  text-align: center;
  background: white;
  color: #232323;
  border-radius: 4px;
}

a.previewTab span {
  border: 1px solid white;
  padding: 2px;
  width: 75px;
  text-align: center;
  border-radius: 4px;
  
}



a.previewTab {
  float: left;
  margin-left:10px;
}

#previewArea{
  padding-top:50px;
}
 
div#previewer .promo-reward, div#previewer .promo-criteria{
  color:#232323;
}

div#saveBar {
  padding: 10px;
  border-bottom: 1px solid #dadada;
  height: 55px;
}

.saveButton{
  padding: 5px 10px 5px 10px;
  background-color: rgb(238 1 88);
  color:white;
  float: right;
  margin-left:10px;
  cursor: pointer;
  border: 0px !important;
}

.cancelButton{
  padding: 5px 10px 5px 10px;
  background-color: rgb(103 103 103);
  color:white;
  float: right;
  margin-left:10px;
  cursor: pointer;
}

.cancelButtonDeactive{
  padding: 5px 10px 5px 10px;
  background-color: rgb(218, 218, 218);
  color:white;
  float: right;
  margin-left:10px;
  cursor: pointer;
}

.saveButtons{
  float:right;
  margin-right:20px;
}

.locationTag{
    font-size: 12px;
    padding: 3px 5px 3px 5px;
    background: #d8d8d8;
    color: grey;
    border-radius: 5px;
}

#openBox {
  margin: 0px auto;
  width: 90%;
  max-width: 1000px;
  min-height: 600px;
  margin-top: 20px;
}


#openBox .leftNav {
  float: left;
  position: relative;
  display: block;
  background: #353535;
  padding: 10px;
  width: 10%;
  color:white;
}

[data-toggle="collapse"]:after {
  display: block;
  float: right;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
content: ">";
transform: rotate(90deg) ;
transition: all linear 0.25s;
color:rgb(238 1 88);
font-size: 25px;
top: -10px;
position: relative
}   
[data-toggle="collapse"].collapsed:after {
transform: rotate(0deg) ;
color:rgb(238 1 88);
}

.criteriaToggler {
  font-weight: 600;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid #e1e1e1
}

span.goldenTitle {
  font-weight: 500;
  color: #e2a800;
}

.criteriaDetails {
  background: #f21d59;
  min-height: 600px;
  padding: 10px;
}

.experienceTag {
  font-size: 12px;
  padding: 3px 5px 3px 5px;
  background: #f1f1f1;
  color: dimgrey;
  border-radius: 5px;
  margin-right: 10px;
}

.vendorModalHeader {
  background-color: #ffffff;
  /* padding: 15px 10px 15px 10px; */
  color: white;
  /* margin-bottom: 15px; */
  border-bottom: 1px solid grey;
  display: flow-root;
  padding: 10px;
}

.ReactModal__Overlay{
  background-color: rgb(0 0 0 / 75%) !important;
}

.vendorModalHeader .saveButtons{
  float:right;
  margin-right:0px;
}

.homebaseApp{
  padding: 20px;
 
max-width: 400px;
margin: 0px auto;
}

#vendor a{
  color: rgb(242, 29, 89) ;
}

#vendor .appMenu a{
  color: white ;
}


#previewTabs a{
  color: white !important;
}

#vendor .DayPicker {
  margin: 0px auto;
  display: block;
  float: right;
  background: white;
    border: 1px grey solid;
}

#vendor li.adminlistitem span {
  font-size: 16px;
}

li.selectedLocationBack{
  background-color: #c9ffc3 !important;
}

.appListCategory{
  clear: both;
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  color: #6d6d6d;
  text-transform: uppercase;
}

.appMenu .appListCategory{
  color: #dcdcdc;
}



#galleryTitleBar {
  background-color: #353535;
  padding: 14px 15px 10px 15px;
  height: 60px;
  color: white;
}

.previewerButton {

  bottom: 10px;
  margin: 0px auto;
  /* text-align: CENTER; */
  display: block;
  /* position: relative; */
  width: 150px;
  border: 2px solid white;
  text-align: center;
  padding: 10px 0px 10px 0px;
  margin-top: 30px
}

.galleryExperience {
  max-width: 300px;
  display: block;
  float: left;
  position: relative;
  margin-right: 30px;
  height: 400px;
}


#experiences{
  padding: 15px;
  margin-top: 50px;
}

.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
  display: none;
}
.carousel .slide {

  background: #fff;
}

.carousel .slide img {

  display: BLOCK;
}

.guestListNav{
  position: fixed;  
  bottom: 0px;
  /* z-index: 2; */
  padding: 10px;
  border-top: 1px solid grey;
  width: 100%;
  background-color: white;
}

/* 
STANDARDS */

.title {
  font-weight: 500;
  font-size: 16px;
  color: #232323;
}

.description {
  font-size: 14px;
  color: #232323;
  clear: both;
  display: block;
}

.changeButton{
  position: relative;
  cursor: pointer;
  /* top: 10px; */
  font-weight: 600;
  display: block;
  clear: both;
  float: none;
  width: 100%;
  background: #f21b59;
  color: white;
  text-align: center;
  margin: 0px auto;
  max-width: 200px;
  padding: 5px 5px 0px 5px;
  right: 0px;
  display: inline-block;
}


span.homeCat.primary {
  font-size: 20px;
  font-weight: 500;
  /* background: red; */
  text-transform: capitalize;
  line-height: 28px;
}

span.homeCat {
  font-weight: 600;
  font-stretch: normal;
    font-size: 20px;
    line-height: 1;
    letter-spacing: -.5px;
    text-transform: uppercase;
  line-height: 24px;
  clear: both;
  display: block;
  text-align: left;
  float: left;
  padding-bottom: 5px;
  color: #282828;
}


span.homeCatCenter {
  font-size: 25px;
  font-weight: 500;
  /* background: red; */
  text-transform: capitalize;
  line-height: 20px;
  clear: both;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #0e0e0e;

}

#coldWeather span.homeCatCenter {
  font-size: 25px;
  font-weight: 500;
  /* background: red; */
  text-transform: capitalize;
  line-height: 28px;
  clear: both;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #0e0e0e;
  text-shadow: 2px 2px #23679e;

}

.control-dots .dot {
  background: #f21d59 !important;
}

.carousel .control-dots{
  margin:0px;
}

.location-info:last-child {
  border: 0px !important;
}

.activeEventLocation{
    width: 100%;
    max-width: 100px;
    border: 1px solid grey;
    margin: 0px auto;
}

.activeEventLocationSelected{
  width: 100%;
  max-width: 100px;
  border: 2px solid rgb(242, 29, 89);
  margin: 0px auto;
}

.material-icons {

  text-rendering: auto !important;

}

#state {
  width: 100%;
  border: 1px solid #999999;
  max-width: 300px;
  border-radius: 15px;
}

.cityBanner {
  background-size: cover;
  max-height: 300px;
  text-align: center;
  color: white;
  min-height: 100px;
  padding: 20px 0px 30px 0px;
}

#horizontalTitles li {
  margin-right: 10px;
  border-right: 1px solid grey;
  padding-right: 10px;
  list-style-type: none;
  display: block;
  position: relative;
  float: left;
  font-size: 15px;
  color: #080808;
  font-weight: 500;
}

#horizontalTitles li:last-child{
  margin-right: 0px;
  border-right: 0px solid grey;
}

#horizontalTitles{
  padding-inline-start: 0px;
  float: left;
}

.cityTagBar{
   

    display: block;
    position: relative;
}

.guide-search{
  width: 80%;
  float: left;
}

.search-input input{
  margin: 0px  !important;
  min-height: 48px;
}

#menu ul{
  list-style: none;
  margin-left: 0px;
  padding-inline-start: 0px;
}


#menu li {
  display: inline-flex;
  padding: 0px 10px 0px 10px;
  color: #f21d59;
  border-right: 1px solid #bd808f;
  text-transform: uppercase;
  cursor: pointer;
}

#menu li:last-child {
  padding: 0px 10px 0px 10px;
  margin-left: 0px;
  border-right: 0px solid white;
}

#home .card{
  margin-bottom: 20px;
}


#menu li:first-child {
  display: inline-flex;
  padding: 0px 10px 0px 0px;
  color: #f21d59;

}

.pointer{
  cursor: pointer;
}
	
.quickText {
  color: white;
  background: #ee0158;
  padding: 2px 5px 2px 5px;
  float: left;
  margin-right: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}



.approvedBorder .UserAvatar--img{
  width: 25px !important;
  height: 25px !important;
  float: left;
}

.unapprovedBorder .UserAvatar--img {
  width: 25px !important;
  height: 25px !important;
  float: left;
}

.rejectedBorder .UserAvatar--img {
  width: 29px !important;
  height: 29px !important;
  float: left;
}

.approvedBorder .UserAvatar--inner{
  border: 3px solid green;

}

.unapprovedBorder .UserAvatar--inner{
 border: 3px solid #575757
}

.rejectedBorder .UserAvatar--inner{
  border: 3px solid red
 }

.UserAvatar {
  margin-bottom: 0px; 
  margin-right: 10px;
}

.unapprovedBorder{
  float:left;
}

.approvedBorder{
  float:left;
}

.approvedName{
  /* float:left; */
  display: inline-block;
  position: relative;
  margin-right: 10px; 
  top:2px;
  color: green;
  text-transform: capitalize;
}

.unapprovedName{
  /* float:left; */
  display: inline-block;
  position: relative;
  margin-right: 10px; 
  top:2px;
  color:  #939393;
  text-transform: capitalize;
}

.rejectedName{
  /* float:left; */
  display: inline-block;
  position: relative;
  margin-right: 10px; 
  top:2px;
  color:  red;
  text-transform: capitalize;
  clear: both;
}



#locationList ul{
  list-style: none;
  margin-left: 0px;
  padding-inline-start: 0px;
  margin-bottom: 0px;
}


#locationList li {
  display: inline;
  padding: 0px 10px 0px 10px;
  color: #585858;
  border-right: 1px solid #cbcbcb;
  cursor: pointer;
  font-size: 14px;
}

#locationList li:last-child {
  padding: 0px 5px 0px 5px;
  margin-left: 0px;
  border-right: 0px solid white;
}


#locationList li:first-child {
  padding: 0px 5px 0px 3px;
}

#eventList .UserAvatar{
  margin-right: 5px
}

#eventList ul{
  list-style: none;
  margin-left: 0px;
  padding-inline-start: 0px;
  margin-bottom: 0px;
}

#eventList li {
  padding: 10px 5px 0px 5px;
}

#eventList li:last-child {

}


#eventList li:first-child {
 
}

#eventList li:nth-child(odd){
  background: white;
}

#eventList li:last-child{
  /* border: 0px !important */
}

#eventList li:nth-child(even){
  background: #f8f7f7;
}

#eventList li .inviteCard:nth-child(even){
  background: #f8f7f7;
}

.criteriaStatus{
  font-weight: 600;
  font-size: 18px;
  background: #e2e2e2;
  padding: 10px;
}

.criteriaStatusLight{
  font-weight: 400;
  font-size: 18px;
  background: #e2e2e2;
  padding: 10px;
}


.editorLong{
  min-height:200px;
}

.editoOverRide p, .editoOverRide span, .editoOverRide li{
  font-size: 14px !important;
  font-family: Roboto, sans-serif !important;
  margin-bottom: 10px
}

.editoOverRide {
  font-size: 14px !important;
  font-family: Roboto, sans-serif !important;
  margin-bottom: 10px
}

.editoOverRideShort {
  overflow: hidden;
  display: block;
  max-height: 100px;
}

.editoOverRide ul{
margin: 20px 0px 20px 20px !important;
padding: 0px 0px 0px 0px !important;
}

.closedCheckBox{
  float: left;
  width: 10px;
  position: relative;
  display: block;
  min-width: 20px !important;
  top: 15px;
  margin: 0px 0px 0px 5px;
}

.activeDay {
  background: #fafafa;
  border: 1px solid #ee0158;
}

.inactiveDay {
  background: white;
  border: 1px solid white;
}

.DayPicker-wrapper{
  max-width: 300px;
    margin: 0px auto;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

h1.welcometitle{
  color: #2d2d2d !important;
}

.appCommunityMenu {
  height: 100%;
  background: #e5e5e5;
  position: fixed;
  width: 120px;
  border-right: 1px solid #222222;
}

#vendorScreenBodyCommunity{
  float: left;
  width: calc(100% - 120px);
}


#vendor .appCommunityMenu .activeMemuItem {
  color: #353535;
  border-bottom: none !important;
  background: #fafafa;
  display: block;
  padding: 10px 0px 3px 12px !important;
  width: 101%;
  border-bottom: 1px solid #838383 !important;
  border-top: 1px solid #838383 !important;
}

#vendor .appCommunityMenu  .appListCategory{
  padding: 10px 0px 0px 12px !important;
}

#vendor .appCommunityMenu  .appTitle{
  margin-bottom: 5px
}

#vendor .appCommunityMenu a{
  color: black ;
}
.appCommunityMenu a{
  padding: 10px 0px 0px 12px !important;
  display: block
}

.appMenuCommunityNew {
  float: left;
  height: 100%;
  width: 120px;
  top: -1px;
  position: relative;
}

.appMenu{
  height: 100%;
  background: #353535;
  position: fixed;
  width: 120px;
}

#vendor .appMenu .activeMemuItem {
  color: #353535;
  border-bottom: none !important;
  background: #fafafa;
  display: block;
  padding: 10px 0px 3px 12px !important;

}

.appMenuNew {
  float: left;
  height: 100%;
  width: 120px;
}
  
#vendor .appMenu  .appListCategory{
  padding: 10px 0px 0px 12px !important;
}

#vendor .appMenu  .appTitle{
  margin-bottom: 5px
}


#authleft .UserAvatar--inner img{
  width: 58px !important;
  height: 58px !important
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}



.container {
  width: 100%;
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-right: auto;
  margin-left: auto;
}
#events .container {
  width: 100%;
  padding-right: 5px !important;
  padding-left: 5px !important;
  margin-right: auto;
  margin-left: auto;
}

.drawerMenuList {
  padding: 0px;
}

.drawerMenuList li a {
  color: #f21d59;
  font-size: 14px;
}

.h2,
h2 {
  font-size: 1.5rem;
  padding-top: 10px;
}

.footer-container ul,
menu,
dir {
  list-style-type: none !important;
 padding: 0px
}



.bannerText{
padding: 3rem 0 2rem
}

@media (min-width: 768px) {
  .bannerText{
    padding: 5.875rem 0 4rem;
  }
}


.bannerMain {
  min-height: 43.75rem;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: black;
  text-align: center;
  height: 100%;
  box-sizing: border-box;
  min-height: 8 00px

}

.bannerWrap{
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.bannerGradient {
  background: rgb(255 255 255 / 40%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to top, rgb(242 27 89) 0, rgba(0, 0, 0, 0) 60%, rgb(255 255 255 / 10%) 100%);
}
.bannerImage {
  height: 100%;
  width: 100%;
  object-fit: cover;
  /* -webkit-transform: scale(1.25) translateY(-10%);
  -moz-transform: scale(1.25) translateY(-10%);
  -ms-transform: scale(1.25) translateY(-10%);
  transform: scale(1.25) translateY(-10%); */
  text-align: center;
}
.bannerImageWrap {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.articleHero {
  height: 150px;
}

.react-card-flip {
  max-height: 400px;
  /* min-height: 400px; */
  margin-bottom: 30px;
  height: 350px;
}

.react-card-front {
  max-height: 400px !important;
  /* min-height: 400px !important; */
}

.react-card-back {
  /* max-height: 400px;
  min-height: 400px; */
}

/* .card {
  height: 400px;
} */

body {
  background-color: #fff !important;
}



.material-icons {
line-height: normal !important;
}

 .backbar .UserAvatar{
   display:  block;
   position: relative;
   top: 2px;
 }

 .supportList li{
   padding: 10px 0px 10px 0px
 }

 @media (min-width: 768px) {.guidePromo {
 height: 100%
}
.editoOverRideShort {
  overflow: hidden;
  display: block;
  max-height: 99px !important;
}
}

.secondSet{
  border-right: 1px solid grey;
  padding-right: 10px
}

.talkBox{
  text-align: center;
  border-top: 1px solid black;
  margin: 30px 0px 0px 0px;
  background: #eaeaea;
  padding: 0px 0px 20px 10px;
}

.rc-time-picker-panel-select li {
font-size: 18px;
}

.rc-time-picker-panel-input{
  font-size: 18px;
  }

  .rc-time-picker-input{
    font-size: 18px;
  }

  .activeBottomNav span {
    color: #921538 !important
}

a.activeBottomNav:hover {
  color: grey;
}

/* HomePage */
.rounded-card{
  width: 95%;
  margin: 0px auto;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 20px;
  margin-top: 10px;
  overflow: hidden;
}

.material-icons{
  cursor: pointer
}



@media (min-width: 768px) and (max-width: 1023px) {

  .multiSlide {
    /* max-height: 200px; */
    float: left;
    display: block;
    position: relative;
    /* height: 200px; */
    min-height: 255px !important;
    width: 32%;
    margin-right: 5px;
    border: 1px solid black;
    border-radius: 20px;
  }

  .multiArticles {
    width: 32%;
  }

  .singleSlide{
    /* padding-left: 10px;
    padding-right: 10px; */
    border: 1px solid rgba(0, 0, 0, 0.125);
    height: 100%
  }



}


@media (min-width: 1024px) {

  .multiSlide {
    /* max-height: 200px; */
    float: left;
    display: block;
    position: relative;
    /* height: 200px; */
    min-height: 255px !important;
    width: 24%;
    margin-right: 5px;
    border: 1px solid black;
  border-radius: 20px;
  }

  .multiArticles {
    width: 24%;
  }

}



@media (max-width: 767px) {

  .singleSlide{
    margin-left: 3px;
    margin-right: 3px;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    height: 100%
  }

  .multiSlide {
    /* max-height: 200px; */
    float: left;
    display: block;
    position: relative;
    /* height: 200px; */
    /* min-height: initial !important; */
    width: 46%;
    margin-right: 5px;
    border: 1px solid black;
    border-radius: 20px;
  }

  .banner-section{
    text-align: center;
    margin-top: 10px;
  }

  .banner-section-nomargin{
    text-align: center;
    padding: 0px 20px 20px 20px !important

  }

  .whitebutton {
    margin-top: 20px;
    margin-bottom: 10px;
    float: none;
    margin-right: 0px !important;
    margin: 0px auto;
    display: block;
    margin-top:20px;  
    max-width: none;
    padding: 5px;
}

  .multiArticles {
    width: 46%;
  }


}

.multiSlide .title{color: rgb(242, 29, 89)}

.multiSlide .card-body{padding: 5px !important}

.multiSlideCallout {
  display: inline-block;
  position: relative;
  width: 48%;
  margin-right: 5px;
  border: 1px solid black;
  border-radius: 10px;
  vertical-align: top;
  max-width: 400px;
  background: rgba(245, 246, 252, 0.7)
}

.multiArticles {
  display: flex;
  position: relative;
  margin-right: 5px;
  border: 1px solid black;
  border-radius: 10px;
  overflow: hidden;
}

#promotedArticles button.control-arrow.control-next {
  right: -30px;
}

.multiArticles:last-child {
  margin-right: 0px;
}

div#homeSections{

  background: white;
}

div#auth-home{
  background: white;
  padding-bottom: 80px;
}



#coldWeather {
  background-image:
  linear-gradient(to bottom,rgba(66, 153, 211, 0.83),rgba(245, 246, 252, 0)),
  url('https://www.daytondailynews.com/resizer/6zfTPX9JszhY__zbEVriek3KWpQ=/814x458/cloudfront-us-east-1.images.arcpublishing.com/coxohio/Z2HEYEHGCNHFLG5F7XSIWI6JGE.jpg');
  width: 100%;
  height: 400px;
  background-size: cover;
  color: white;
  padding: 20px;
  display: block;
  margin-top: 30px;
  margin-bottom: 20px;
}

#coldWeather .carousel, #coldWeather .slide {
  background: none;
}

.spotlightText{
  position: absolute;
  display: block;
  bottom: 10px;
  max-width: 600px;
  padding: 20px;
}




.spotlightTitle{
  font-size: 30px;
  font-weight: 600;
  clear: both;
  display: block;
}

.carousel .control-next.control-arrow:before {
  border-left: 12px solid transparent;
}

#topPick .carousel .control-arrow, .carousel.carousel-slider .control-arrow{
  transition: none;
  opacity: 1;
  max-height: 90%;
  top: 50px !important;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
  transition: none;
  opacity: 1;
}

.carousel .control-prev.control-arrow:before {
  border-right: 12px solid transparent;
}


#promotedArticles .carousel .control-prev.control-arrow:before{
  /* border-right: 12px solid #ffffff !important; */
}

.carousel .control-prev.control-arrow {
  left: -30px;
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  margin: 0 5px;
  display: inline-block;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  content: '';
}

.DayPicker-Day--disabled {
  cursor: default;
  pointer-events: none;

}

#googleadd div{

}

.carousel.carousel-slider {

  overflow: inherit;
}

#guide-category .multiSlide{
  width: 95%;
  margin-bottom: 30px;
}

.white p{color: white}

.seeMoreLeft{
  /* float:left; */
  display: block;
  clear: left;
  margin-bottom: 10px;
}

.homeMoodBar{
  background-color: #f21d59;
  padding: 10px 0px 0px 0px;
  border-bottom: 1px solid #30000f;
}

.homeMoodBar ul{
 margin: 0px
}

#auth-home .homeMoodBar a {
  text-decoration: underline;
}


.announcementBox {
  background-color: #f9f7f7;
  padding: 17px 10px 10px 10px;
  text-align: center;
  color: #303030;
  border-top: 3px solid #1c1c1c;
  border-bottom: 3px solid #1c1c1c;
}

#helpBox{
  background: white;
  position: relative;
  display: block;
  top: -5px;
  padding: 30px;
  border-bottom: 6px solid #474747;
}

.eventDate{
  color: #fafafa;
    background: #f21d59;
    font-size: 25px;
    padding: 5px;
    border-radius: 5px;
}

.topTextBox{
    padding: 10px;
    background: #353535;
    text-align: center;
    font-size: 18px;
    color: white;
    font-weight: 400;
}

.toolbarSignup {
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
}

#vendorTopLink{display: none}

#vendorTopLink {
  text-align: center;
  padding: 10px;
  background: #f21b59;
  margin-bottom: 5px;
}
#vendorTopLink a{
 color: white;
font-weight: 600;
}

#vendorAdmin .rdw-editor-wrapper{
  min-height: 400px
}


.rdw-editor-wrapper{
  background: white;
}


@media (max-width: 640px) {


  .carousel.carousel-slider .control-arrow {
    top: 45px;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
    height: 90%;
  }

  .guideBanner{
    min-height: 175px;
  }


.whitebuttonNoStack {
  border: 1px solid #f21d59 !important;
  padding: 5px;
  position: relative;
  color: #f21d59 !important;
  min-width: 100px;
  background: white;
  cursor: pointer;
  display: block;
  max-width: 400px;
  margin: 0px auto;
  text-align: center;
  font-weight: 500;
}

  .trevelBanner{
    max-height: 120px;
    min-height: 120px;
  }

  .trevelBannerTop{
    padding-top: 10px;
    font-size: 23px;
    max-width: 400px;
    margin: 0px auto;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .trevelBannerBottom{
    font-size: 25px;
  }




  .homeSection{ 
    padding: 0px 0px 0px 0px;
    overflow: hidden;
    margin-top: 30px;
    background: white;
    margin-bottom: 10px
  }

  span.homeCat{
   font-size: 19px !important; 
   padding: 0 0 10px 5px;
   color: #282828
  }

  .seeMoreLeft {
    padding: 0px 0 0 5px;
    margin-bottom: 5px;
    top: -5px;
    position: relative;
}
}

@media (min-width: 992px) {
  .card-body {
    padding: 15px !important;
}
}

@media (min-width: 641px) {
  .guideBanner{
    min-height: 350px;
  }

  .trevelBanner{
    min-height: 200px
  }


  .whitebuttonNoStack {
    border: 1px solid #f21d59 !important;
    padding: 5px;
    position: relative;
    color: #f21d59 !important;
    min-width: 100px;
    background: white;
    cursor: pointer;
    display: block;
    max-width: 400px;
    margin: 0px auto;
    text-align: center;
    font-weight: 500;
  }



  .trevelBannerTop{
    font-size: 30px;
    max-width: 400px;
    margin: 0px auto;
    line-height: 35px;
    margin-bottom: 20px;
   }
 
   .trevelBannerBottom{
     font-size: 35px;
   }
 

  .homeSection{ 
    padding: 0px 30px 0px 30px;
    margin-top: 30px;
    background: white;
  }
}


.trevelBannerText{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    width: 100%;
}

.paginationOutter {
  display: block;
  margin-bottom: 30px;
  margin-left:50px;
  width: 100px; /* only show 5 dots */
  overflow: hidden;
  height:18px;
  position: relative;
  
}

  .dot {
  display: inline-block;
  background: white;
  margin: 4px;
  vertical-align: middle;
  border-radius: 10px;
  height: 10px;
  width: 10px;
}


/* 3rd */
.dot.active {
  background: #f21d59;
  width: 10px;
  height: 10px;
}

#vendorScreenBody{
  float: left;
  width: calc(100% - 120px);
}



a.mobileMenuButton {
  display: block;
  position: relative;
  text-align: center;
  width: 100%;
  padding: 10px;
  background: #464646;
  margin-bottom: 20px;
  color: white !important;
}

.homeSection:first-child{
  margin-top: 0px !important
}

.trevelBanner{
  background-color: #f21d59
}

.squareSearch{
  background: white;
  border: 1px solid white;
  border-radius: 5px;
  width: 46px;
}

.getGoing{
  background: white;
    height: 100%;
    padding: 10px;
    border-top: 1px solid #3b131d;
    border-right: 1px solid #3b131d !important;
    border-left: 1px solid #3b131d !important;
}

#package{
  height: 100%
}

.announcementBoxGrey {
  background-color: #f8f8f8;
  padding: 20px 10px 10px 10px;
  text-align: center;
  color: black;
  border-bottom: 1px solid #181818
}

.guideAddButton {
  display: block;
  position: absolute;
  padding: 5px;
  text-align: center;
  width: 100%;
  padding: 3px 2px 2px 2px;
  background: #ee0158;
  color: #f21d59;
  border-left: 2px solid rgb(255 255 255) !important;
  border-bottom: 2px solid rgb(255 255 255) !important;
  border-top: 2px solid #f21d59 !important;
  border-right: 2px solid #f21d59 !important;
  width: 45px;
  right: 0px;
}

.guideAddButtonMobile {
  display: block;
  position: absolute;
  padding: 5px;
  text-align: center;
  width: 100%;
  padding: 3px 2px 2px 2px;
  background: #ee0158;
  color: #f21d59;
  border: 2px solid rgb(255 255 255) !important;
  border-bottom: 0px;
  width: 45px;
  right: 0px;
}

/* .guideAddButton:hover {
  display: block;
  position: relative;
  padding: 5px;
  text-align: center;
  width: 100%;
  padding: 5px;
  background: #f21d59;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-bottom: 0px;
}

.guideAddButtonLink:hover {
  color: white;
} */


#trevelAgentBar {
  min-height: 100px;
  background: #f4f4f4;
  border-bottom: 1px solid #dad4d4;
  padding: 15px 10px 10px 10px;
}

.distanceRight {
  font-size: 12px;
  color: #3e3e3e;
  top: 3px;
  display: block;
  position: relative;
  float:right
}

div#eventList {
  max-width: 1100px;
  margin: 0px auto;
  background-color: white;
}

.react-confirm-alert-overlay{
  background: rgb(29 29 29 / 90%);
}

.react-confirm-alert {
  background: white;
  width: 100%;
  max-width: 500px;
  padding: 20px 20px 40px 20px;
}

#home #events{
  background-color: #fafafa !important;
}

#snackbar {
  position: fixed;
  z-index: 1000;
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: auto;
  padding: 0.625rem 1rem;
  border-radius: 1px;
  border: transparent;
  background-color: hsl(0deg 0% 16%);
  color: white;
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 350px;
  min-height: 50px;
  transition: opacity 0.5s ease-out;
}

/**
 * ==============================================
 * Dot Bricks
 * ==============================================
 */
 .dot-bricks {
  position: relative;
  top: 8px;
  left: -9999px;
  width: 13px;
  height: 13px;
  border-radius: 2px;
  background-color: white;
  color: white;
  -webkit-box-shadow: 9991px -16px 0 0 white, 9991px 0 0 0 white, 10007px 0 0 0 white;
  box-shadow: 9991px -16px 0 0 white, 9991px 0 0 0 white, 10007px 0 0 0 white;
  -webkit-animation: dotBricks 2s infinite ease;
  animation: dotBricks 2s infinite ease;
  margin: 0px auto;
  margin-top: 20px;

}

@keyframes dotBricks {
  0% {
    box-shadow: 9991px -16px 0 0 white, 9991px 0 0 0 white, 10007px 0 0 0 white;
  }
  8.333% {
    box-shadow: 10007px -16px 0 0 white, 9991px 0 0 0 white, 10007px 0 0 0 white;
  }
  16.667% {
    box-shadow: 10007px -16px 0 0 white, 9991px -16px 0 0 white, 10007px 0 0 0 white;
  }
  25% {
    box-shadow: 10007px -16px 0 0 white, 9991px -16px 0 0 white, 9991px 0 0 0 white;
  }
  33.333% {
    box-shadow: 10007px 0 0 0 white, 9991px -16px 0 0 white, 9991px 0 0 0 white;
  }
  41.667% {
    box-shadow: 10007px 0 0 0 white, 10007px -16px 0 0 white, 9991px 0 0 0 white;
  }
  50% {
    box-shadow: 10007px 0 0 0 white, 10007px -16px 0 0 white, 9991px -16px 0 0 white;
  }
  58.333% {
    box-shadow: 9991px 0 0 0 white, 10007px -16px 0 0 white, 9991px -16px 0 0 white;
  }
  66.666% {
    box-shadow: 9991px 0 0 0 white, 10007px 0 0 0 white, 9991px -16px 0 0 white;
  }
  75% {
    box-shadow: 9991px 0 0 0 white, 10007px 0 0 0 white, 10007px -16px 0 0 white;
  }
  83.333% {
    box-shadow: 9991px -16px 0 0 white, 10007px 0 0 0 white, 10007px -16px 0 0 white;
  }
  91.667% {
    box-shadow: 9991px -16px 0 0 white, 9991px 0 0 0 white, 10007px -16px 0 0 white;
  }
  100% {
    box-shadow: 9991px -16px 0 0 white, 9991px 0 0 0 white, 10007px 0 0 0 white;
  }
}

.otherVendorReward {
  padding: 10px;
  border: 1px solid #9f9f9f;
}

.carousel .slide iframe {
  margin: 0px !important
}



.bottomActions .white{
  cursor: pointer;
}


.ReactModal__Content{
  min-width: auto !important
}

.hideThis { display: none } 

.inactiveLink{
  color: #3f3f3f;
  text-decoration: none !important;
  font-weight: 500;
}

.inviteHeader{
  display: none;
}


.dayDotActive{
  background: red;
  color: white !important;
  PADDING: 10px;
  float: left;
    border-radius: 40px 40px;
    PADDING: 8px 5px 5px;
    width: 40px;
    height: 40px;
    text-align: center;
    margin-right: 5px
}

.dayDotInActive{
  float: left;
  border-radius: 40px 40px;
  background: grey;
  color: white !important;
  PADDING: 8px 5px 5px;
  width: 40px;
  height: 40px;
  text-align: center;
  margin-right: 5px
}

.rotateArrow i{
  transform: rotate(180deg);
}

.rdw-list-dropdown{
  z-index: 0 !important
}

#authtoolbar .UserAvatar--inner{
background-color: #f21b59 !important;
}

#vendor input[type=radio] {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
  transform: scale(1.5);
}

.taMessage{
  display: block;
  clear: both;
  padding: 5px 6px 5px 6px;
  border: 1px solid #ffffff;
  width: 100%;
  margin-bottom: 10px;
  background: #3f3f3f;
  color: white;
  text-align: center;
}

.taMessage  span {
  font-weight: 300
}

.matchedTag{
  font-size: 11px;
    display: block;
    border-radius: 7px;
    background: #ffffff;
    padding: 1px 5px;
    color: #1f1f1f;
    border: 1px solid #f21b59;
    margin-top: 4px;
    float: left;
    margin-right: 5px;
}

.hand{
  cursor: pointer;
}