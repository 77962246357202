button.contact-submit {
  /* position: absolute;
  display: flex;
  bottom: 0px;
  margin-left: 12%;
  cursor: pointer; */
  float: right;
}

#contactForm input {
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;
}

#contactForm textarea {
  padding: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.favicon {
  width: 45px;
  /* text-align: center; */
  vertical-align: top;
}

span.social-button {
  padding: 20px 10px 0 0;
}

#contactForm{
  padding: 10px;
}
