.header-container {
  /* text-align: left;
  width: 100%; */
}

.header {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.cardsection {
  height: 340px;
  width: 400px;
  margin: 10px 60px 10px 0;
  float: left;
}

.hero {
  width: 100%;
}

body {
  background-color: #efefef !important;
}

.right {
  float: right;
}

.left {
  float: left;
}

#card-button {
  background-color: #f21d59;
  margin-left: 36%;
  margin-top: 3%;
}

#back-card-button {
  background-color: orange;
}

.locations {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

.location2 {
  margin-left: 1%;
}

.location3 {
  margin-left: 1%;
}
p.location {
  margin: 0;
}

.stop {
  color: #f21d59;
  margin: 0;
}

.card-back-buttons {
  justify-content: space-around;
  width: 100%;
  display: inline-flex;
}

button#purchase-card-button {
  background-color: #f21d59;
}

.location-1,
.location-2,
.location-3 {
  display: inline-flex;
  padding: 2%;
  width: 100%;
}

.location-photo {
  background-color: grey;
  margin-left: 2%;
  height: 75px;
  width: 75px;
}

.package-slider {
  padding-bottom: 2%;
}

.side-card {
  border: 1px solid blue;
}

.side-thumb-image {
  height: 50px;
  width: 50px;
  background-color: grey;
}

#packages .card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: 0.3s;
}

h5.card-title {
  color: #f21d59;
}

.card-drawer {
  position: absolute;
  height: 100%;
  background-color: white;
  margin-left: 100%;
  display: block;
  z-index: 10;
  visibility: hidden;
  border: 1px solid grey;
  width: 200px;
  overflow: scroll;
}

.side-image {
  height: 30%;
  border: 1px solid black;
}

.card:hover .card-drawer {
  visibility: visible;
}

#packages .card {
  z-index: 1;
  /* height: 350px; */
  margin-bottom: 15px;
}

button.buy-package {
  background-color: #f21d59;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
}

button.buy-package:hover {
  background-color: #b81342;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

.topcard {
  z-index: 6;
}

.package-card:nth-child(2n + 2) .card-drawer {
  /* visibility: visible; */
  left: -200px;
  margin-left: 0px;
}

.package-card .thumbnail {
  width: 100px;
  height: 90px;
  margin: 0px auto;
  position: relative;
}

.package-card h2 {
  font-size: 20px;
  justify-content: center;
  text-align: center;
  color: #b81342;
  font-weight: 400 !important;
}

.package-card .title {
  font-size: 14px;
}

.card-drawer {
  justify-content: center;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-drawer .row {
  justify-content: center;
}

.cardactions {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

#package .card,
#location .card {
  border: 1px solid rgb(59 18 29) !important
}
