.informationContainer {
  /*text-align: center;*/
}

p {
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

a.social-button {
}
