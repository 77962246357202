.location-info {
  border-bottom: 1px solid #e0e0e0;
  /* border-radius: 10px; */
  margin-top: 10px;
  padding-bottom: 10px;
}

.location-info:first-child {
  margin-top: 0px;
}

.itinerary {
  padding-top: 0px !important;
}

.itinerary .row:first-child {
  margin-top: 0px !important;
}

.voucher {
  display: flex;
  padding: 1%;
}

button.purchase {
  padding-left: 0.5% !important;
  padding-right: 0.5% !important;
}

.title {
  padding-top: 0px !important;
}

.card-section p {
  margin: 0px;
}

.thumbnail-col {
  max-width: 115px !important;
}

/*button.purchase {
  background-color: white;
  color: #f21d59;
  border-radius: 7px;
  font-size: 15px;
  padding: 1%;
  border: 1px solid #f21d59;
  margin-left: auto;
  margin-right: auto;
  display: relative;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 500ms ease;
}

button.purchase:hover {
  background-color: #f21d59;
  color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
}

button.purchase {
  display: inline-block;
  margin-left: 2%;
  padding: 1%;
  background: none;
  color: #f21d59;
  position: relative;
  transition: color 0.25s ease;
  border: 2px solid #f21d59;
  border-radius: 5px;
}

button.purchase:hover {
  color: white;
}

button.purchase::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #f21d59;
  transform-origin: left;
  transition: width 0.25s ease;
  z-index: -1;
}

button.purchase:hover::after {
  width: 100%;
}*/

.maptext {
  font-size: 20px;
}
