.phone-only {
  display: none;
}
/* 
.maincontent {
  height: 100%;
} */



.toolbar__navigation {
  margin: 0px auto;
  max-width: 1200px;
}

.toolbar__toggle-button {
  float: left;
  top: 10px;
  position: relative;
}

.toolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 20px;

}

#full-nav .toolbar__logo {
  width: 130px;
  float: left;
  top: 10px;
  position: relative;
}



#full-navSignup .toolbar__logo {
  width: 300px;
  float: left;
  top: 10px;
  position: relative;
}

.toolbarSignup #mobile-nav .toolbar__logo {
  width: auto;
  align-self: center;
  top: 0px;
  position: relative;
}


#mobile-nav .toolbar__logo {
  align-self: center;
  top: 3px;
  position: relative;
 
}



.toolbar__logo a img {
  width: 110px;
  max-width: 110px;
  margin: 0px auto;
  position: relative;
  display: block;
}

.toolbarSignup .toolbar__logo a img {
  width: 70%;
  max-width: 200px;
  right: 5px;
  position: relative;
}

.authtoolbar .toolbar__logo a img {

  max-width:200px;

}

/* .toolbar__logo a:hover {
  color: white !important;
  text-decoration: none !important;
  font-size: 20px;
  float: left;
} */

.toolbar_navigation-items {
  float: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toolbar_navigation-item {
  float: right;
  padding-left: 20px;
  color: #2f2f2f !important;
  cursor: pointer;
  font-size: 14px;
}

.toolbar_navigation_authlinks {
  float: right;
  padding-top: 5px;
}

.toolbar_navigation-item:hover {
  text-decoration: none;
}

a.toolbar_navigation-item:hover {
  color: #f21d59 !important;
  text-decoration: none;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.eventDropDown {
  float: inherit;
  overflow: hidden;
  height: 50px;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.75);
  z-index: 1;
  top: 5px;
}
.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content a:hover {
  text-decoration: none;
  color: #f21d59;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.dropdown-content li {
  list-style: none;
}

.eventDropDown:hover .dropdown-content {
  display: block;
  margin-top: 3%;
}

/* Media Queries */

@media (max-width: 800px) {
.container{
  max-width: 100% !important;
}

  #full-nav {
    display: none;
  }

  .toolbar__navigation {
    padding: 0px 0px 0 5px;
  }

  .thumbnail {
    max-width: 100px !important;
    float: left;
    margin-right: 15px;
  }

  #mobile-nav {
    display: block;
    height: 55px;
  }

  #mobile-authlinks {
    display: block;
    height: 35px;
    border-top: 1px solid grey;
    background: white;
    border-bottom: 10px;
  }

  .maincontent {
    position: relative;
    top: 80px;
    margin-top: 0px;
    background: #fafafa;
  }

  #headers {
    position: fixed;
    z-index: 9999999;
    top: 50px !important;
    width: 100% !important;
  }

  #headersTop {
    position: fixed;
    z-index: 2;
    top: 0px;
    width: 100% !important;
  }

  #headersTopSecondary {
    background: white;
    top: 40px;
    width: 100%;
    z-index: 2;
    position: fixed;
    border-bottom: 1px solid #b9143a;
  }

  #headersTopSecondaryRelative {
    background: white;
    width: 100%;
    z-index: 2;
    border-bottom: 1px solid #b9143a;
  }

  #headersTopSecondaryEighty {
    background: white;
    top: 80px;
    width: 100%;
    z-index: 2;
    position: fixed;
    border-bottom: 1px solid #b9143a;
    border-top: 1px solid #b9143a;
  }

  #buttonbar {
    background: white;
    width: 100%;
    z-index: 2;
    position: fixed;
    border-bottom: 1px solid #b9143a;
    border-top: 1px solid #b9143a;
  }

  #toolbar {
    z-index: 999999;
  }

  #spacer {
    display: none;
  }
}

@media (min-width: 801px) {
  #headersTopSecondary {
    background: white;
    top: 40px;
    width: 100%;
    border-bottom: 1px solid #b9143a;
    z-index: 2;
    position: fixed;
  }

  .toolbar__navigation {
    padding: 0px 20px 5px 20px;
  }

  #headersTopSecondaryRelative {
    background: white;
    width: 100%;
    border-bottom: 1px solid #b9143a;
    z-index: 2;
  }

  #headersTopSecondaryEighty {
    background: white;
    top: 80px;
    width: 100%;
    z-index: 2;

    border-bottom: 1px solid #b9143a;
    border-top: 1px solid #b9143a;
  }

  #headersTopSecondaryEightyWidthless {
    background: white;
    top: 80px;
    z-index: 2;

    border-bottom: 1px solid #b9143a;
    border-top: 1px solid #b9143a;
  }

  .breakleft {
    margin-left: -350px;
  }

  .maincontent-push {
    /* top: 15px; */
    /* position: relative;
    height: 100%; */
  }

  .breakright {
    margin-right: -350px;
  }

  #home .intro h2 {
    margin-top: 50px;
  }

  #full-nav {
    display: block;
    height: 55px;
  }

  .page-header {
    font-size: 18px;
  }

  #mobile-nav {
    display: none;
  }

  #mobile-authlinks {
    display: none;
  }

  .maincontent {
    position: relative;
    margin-top: 40px;
    background: #fafafa;
    /* background: linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%); */

   
  }

  #events{
    /* background: linear-gradient(180deg, rgba(242,29,89,1) 0%, rgba(167,25,65,1) 59%); */
    /* background-color: #efefef; */
    height: 100%;
  }
  #event{
    background: linear-gradient(180deg,rgba(167,25,65,1) 0%, rgba(242,29,89,1) 59%);
    height: 100%;
  }
  #location{
   background: linear-gradient(180deg,rgba(167,25,65,1) 0%, rgba(242,29,89,1) 59%);
  }
  #package{
    background: linear-gradient(180deg,rgba(167,25,65,1) 0%, rgba(242,29,89,1) 59%);
    height: 100%;
  }
  
}

#inviteFull{
  background: linear-gradient(180deg,rgba(167,25,65,1) 0%, rgba(242,29,89,1) 59%);
}

@media (min-width: 377px) {
  .ReactModal__Content {
    border: 1px solid rgb(68, 68, 68) !important;
  }
}

@media (max-width: 415px) {
  p.subline {
    font-weight: 400 !important;
  }

  .ReactModal__Content {
    border: 0px !important;
    width: 100% !important;
  }

  #event h2 {
    font-weight: 400 !important;
    font-size: 20px !important;
  }

  .phone-only {
    display: block;
  }

  .homebar{
    margin-top:50px
  }
}

@media (min-width: 576px) {
  .container {
    /* max-width: 100% !important; */
  }
}

@media (max-width: 760px) {
  .card-drawer {
    display: none !important;
  }
}

@media (min-width: 767px) {

  #home h2 {
   
    line-height: 58px !important;
}
}

@media (max-width: 767px) {
  .calltoaction h2 {
    font-size: 30px !important;
    font-weight: 400 !important;
  }
  .homebar{
    margin-top:50px
  }
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  

  #home h2 {
    font-size: 32px !important;
    line-height: 48px !important;
  }

}

.callSection h3 {
  font-size: 22px !important;
 
}

#home li p {
  color: #808080;
  font-size: 14px !important;
  line-height: 15px !important;
  margin-bottom: 10px;
}

#home li  {

  margin-bottom: 10px;
}

  #home h3,
  .features h3 {
    font-size: 30px !important;
    line-height: 30px !important;
    font-weight: 200 !important;
    margin-top: 30px;
    margin-bottom: 15px !important;
    padding-bottom: 10px
  }
  .maxed-image {
    max-width: 95%;
  }
  .col-12:nth-child(even) {
    /* position: static !important; */
    width: 100%;
    /* padding: 0 10px; */
  }
  .features .feature.padding-top {
    padding-top: 10px !important;
  }


@media (min-width: 768px) {
  .wrapper,
  .wrapper.top {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  .mobile {
    display: none;
  }
  .desktop {
    display: block;
  }
  .maxed-image {
    max-width: 95%;
  }
  .reverse .col-md-6:nth-child(even) {
    right: 50%;
  }
  .reverse .col-md-6:first-child {
    left: 50%;
  }
}

#full-nav .nav-signup {
  display: inline-block;
  background-color: #f21d59;
  border: none;
  color: #fff !important;
  padding: 7px 11px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #bd0036;
}

#full-nav .nav-business {
  display: inline-block;
  /* background-color: #f21d59; */
  border: none;
  color: #f21d59 !important;
  padding: 7px 11px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #bd0036;
}

#full-nav .nav-login,
#full-nav .nav-logout {
  display: inline-block;
  background-color: #fbfcfd;
  border: none;
  color: #777;
  padding: 7px 11px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  margin-right: 10px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #adadad;
}

#mobile-authlinks .nav-signup {
  display: inline-block;
  border: none;
  color: #f21d59 !important;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  padding: 7px 0px;
}

#mobile-authlinks .nav-business {
  display: inline-block;
  border: none;
  color: #f21d59 !important;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
}

#mobile-authlinks .nav-login,
#mobile-authlinks .nav-logout {
  display: inline-block;
  color: #777;
  padding: 7px 11px;
  margin-right: 10px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
}

header .row {
  margin-top: 0px !important;
}

.wrapper {
  max-width: 1200px;
  padding: 0px 20px 0 20px;
}

.toolbar_navigation_authlinks_mobile {
  float: right;
}
