.header {
  /* display: inline-flex; */
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

p {
  color: grey;
}

.card:hover p {
  color: black;
  transition-duration: 1s;
}

p.article-content {
  font-size: 14px;
}

h2.title {
  color: black;
}
a:hover {
  text-decoration: none !important;
}

.article-link {
  color: black !important;
}

.article-link:hover {
  text-decoration: none !important;
  /*color:grey !important;*/
  opacity: 0.5;
}

.card-container {
  margin-top: 4%;
}

p.article-content {
  font-size: 14px;
}

[type="search"] {
  overflow: visible;
  width: 100%;
  margin-bottom: 10px !important;
  /* max-width: 600px; */
  border-radius: 5px;
  padding: 6px 15px;
  border-width: 1px;
  border-style: solid;
  border-color: #a7a7a7;
  margin-right: 10px;
  clear: both;
  color: #424242;
}

.article-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  height: 400px;
}

#articles .card,
#relatedArticles .card {
  margin-bottom: 15px;
}

.text-area {
  width: 90%;
  align-self: center;
}

.article-slider {
  width: fit-content;
}

#packages.card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  transition: 0.3s;
}

#relatedArticles {
  border-top: 1px solid grey;
  padding-top: 10px;
  margin-top: 15px;
}
