.cardsection {
  height: 340px;
  width: 400px;
  margin: 10px 60px 10px 0;
  float: left;
}

.hero {
  width: 100%;
}

body {
  background-color: #efefef !important;
}

.right {
  float: right;
}

.left {
  float: left;
}

#right-filter {
  height: 100%;
  /* border-top: 1px solid #e2e2e2; */
  padding-top: 20px;
}

#right-filter ul {
  list-style: none;
  padding: 0px;
}

#right-filter h4 {
  font-size: 18px;
}
