.eventCard h2 {
  font-weight: 400 !important;
  font-size: 18px;
  color: #f21e59 !important;
  line-height: 15px;
}

.eventCard {
  padding-bottom: 20px;
  border-bottom: 1px solid #c1c1c1;
  margin-top: 5px;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
  margin-bottom: 0px;
  width: 100%;
  /* display: flex; */
}






.cardBodyContainer {
  display: inline-flex;
}

.eventCard a {
  text-decoration: none;
  color: rgb(55, 58, 58);
}

.eventCard a:hover {
  text-decoration: none;
  color: rgb(55, 58, 58);
}

.eventCard p {
  /* padding-left: 0.5rem; */
  margin-bottom: 1px;
}

p.description {
  font-size: 16px;
  padding-bottom: 10px;
}

.cardBody {
  width: 66%;
  padding: 0px 10px 0px 10px;
}

@media (min-width: 641px) {
  .eventHero {
    width: auto;
    height: 200px;
    background-color: grey;
  }
}

@media (max-width: 640px) {
  .eventHero {
    width: auto;
    height: 100px;
    background-color: grey;
  }
}
