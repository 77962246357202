.button-container {
  width: 30%;
  height: 50%;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.event-button {
  background-color: #f21d59;
  width: 100%;
  height: 100%;
  margin-top: 0;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
}

.event-button p {
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 15px;
  margin: 0px;
}

.event-button-link {
  text-decoration: none !important;
  width: 20%;
  height: 50%;
}

.date-type-container {
  margin-left: auto;
  margin-right: auto;
}
