.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: -10px;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  padding: 10px;
}

.side-drawer.open {
  transform: translateX(0);
}



.side-drawer ul {
  list-style: none;
}


#authsidemenu {
  float: left;
  height: 100%;
  position: relative;
  display: block;
}

.modelink {
  color: #ffffff;
  text-align: center;
  display: block;
  padding: 5px;
  border: 1px solid #f21d59;
  margin-bottom: 10px;
  background: #f21d59;
}
