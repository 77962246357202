.footer-container {
  /* background-color: #999999; */
  background-color: white;
  display: flex;
  width: 100%;
  padding-bottom: 40px;
  border-top: 2px solid #070707;
}

/* .footer-container a {
  color: white;
} */

.footer-container a {
  color: #f21d59;
}

.footer-container a:hover {
  color: #f21d59;
}

.footer-text {
  color: white;
  padding-top: 15px;
}

.footer-left-column {
  width: 33.3%;
  margin-top: 5%;
}

.footer-middle-column {
  width: 33.3%;
  margin-top: 5%;
}

.footer-right-column {
  float: right;
}
.home-sign-up-container {
  display: flex;
}

.home-sign-up-field {
  background-color: white;
  width: 40%;
  height: 30px;
  border: 2px solid #f21d59;
}

.footer-nav-left li,
.footer-nav-middle li,
.footer-nav-right li {
  padding: 1%;
}
