.cardsection {
  height: 340px;
  width: 400px;
  margin: 10px 60px 10px 0;
  float: left;
}

.hero {
  width: 100%;
}

body {
  background-color: #efefef !important;
}

.right {
  float: right;
}

.left {
  float: left;
}

#right-filter ul {
  list-style: none;
  padding: 0px;
}

#right-filter a {
  color: #f21d59;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  cursor: pointer;
}

#right-filter h4 {
  font-size: 18px;
}
