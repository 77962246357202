.newcontainer {
  border-bottom: 2px solid #c1c1c1;
  padding: 1%;
  margin-top: 1%;
  border-width: 85%;
}

.newcontainer h1 {
  color: #f21d59 !important;
  text-align: center !important;
}

.newcontainer h2 {
  color: #505050;
  font-size: 16px !important;
  padding: 1% 3% 1% 3%;
}

.newcontainer ul {
  margin-left: 3%;
  list-style: none;
}

.newcontainer ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #f21d59; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.app-gif-container {
  height: 250px;
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #c1c1c1;
  border-radius: 2%;
}

.app-gif {
  height: auto;
  width: auto;
  background-color: grey;
  margin: 1%;
  padding: 1%;
  margin-top: 1%;
}

.sign-up-container {
  /* border: 1px solid #c1c1c1;
    border-radius: 1px; */
  padding: 1%;
  margin-top: 1%;
}

.sign-up-container h1 {
  color: #f21d59 !important;
  text-align: center !important;
}

.sign-up-container h2 {
  color: #505050;
  font-size: 16px !important;
  padding: 1% 3% 1% 3%;
}

.sign-up {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

label#password {
  margin-left: 2%;
}

.lower-sign-in {
  margin-top: 2%;
}

input#password {
  margin-left: 1%;
}

input#username {
  margin-left: 1%;
}

.lower-sign-in {
  text-align: center;
}

label.keep-logged-in {
  margin-left: 1%;
}

input[type="submit"] {
  width: 25%;
}

.sender-name {
  color: #f21c59;
}

#invitation {
  /* border-right: 1px solid #cecdcd; */
  border-radius: 5px;
  background-color: #ffffff;
  margin-top: 20px;
}

.accept-button {
  display: inline-block;
  background-color: #f21d59;
  border: none;
  color: #fff !important;
  padding: 7px 11px;
  border-radius: 3px;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  border: 1px solid #bd0036;
}

#invitation h2 {
  padding-top: 0px !important;
}

#invitation h3 {
  font-size: 30px;
  line-height: 44px;
  font-weight: 200;
  margin-top: 00px;
  margin-bottom: 5px;
}

.invite-label {
  font-weight: 400;
  color: #212529;
}

.red-back p,
.red-back h2 {
  color: white !important;
}

.red-back .section {
  padding-top: 20px;
}

.package h3 {
  font-size: 25px;
  line-height: 31px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 15px;
}

/* .package a {
  color: #3c3c3c !important;
} */

.itinerary {
  /* border-top: 1px solid #e0e0e0; */
  padding-top: 15px;
}

.address {
  font-size: 14px;
  color: #4b4b4b;
  display: block;
}
